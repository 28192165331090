@import url("bootstrap/dist/css/bootstrap.min.css");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
:root {
  --animate-duration: 1s;
  --animate-delay: 1s;
  --animate-repeat: 1;
  --darkByzantineBlue: #333c5b;
  --darkColor: #273848;
  --darkShadeBlue: #2e323c;
  --darkCharcoal: #333;
  --grayishBlue: #8a99b5;
  --veryLightShadeCyanBlue: #bcd0f7;
  --bright-gray: #efefef;
  --jordy-blue: #86b7fe;
  --white: #fff;
  --black: #000;
  --dark-red: #333;
  --red: #ff0000;
  --errorRed: #dc3545;
  --columbia-blue: #d3d7e9;
  --headerBackground: linear-gradient(
    107.46deg,
    #ed1f68 11.65%,
    #264ac9 94.24%
  );
  --veryDarkShadeCyanBlue: #1f2327;
  --mediumDarkShadeCyanBlue: #0062cc;
  --mediumDarkShadeBlue: #2e3d5f;
  --blackWith20: rgba(0, 0, 0, 0.2);
  --bigStone: #1a243a;
  --mediumLightShadeCyanBlue: #42a8ff;
  --veryDarkDesaturatedBlue: #1a233a;
  --veryDarkShadeBlue: #0c1425;
  --lightShadeGrey: #d2d2d2;
  --veryLightShadeGray: #efefef;
  --mediumDarkShadeGreen: #2fb700;
  --mediumDarkShadeRed: #cc0000;
  --blue: blue;
  --cyanBlue: #007ae1;
  --grayishNavy: #343f5f;
  --shadeBlue: #264ac9;
  --vividPink: #ed1f68;
  --mediumLightShadeBlueMagenta: #9f76f5;
  --lightShadeBlue: #cfd1d8;
  --mediumLightShadeMagentaPink: #f466c4;
  --darkMidnightBlue: #003366;
  --mainlyBlue: #9fa8b9;
  --blueEbonyClay: #272e48;
  --blueMagenta: #826ac3;
  --blueCoolColor: #768287;
  --strongBlue: #0168b5;
  --claret: #7f1734;
  --mediumLightShadeRed: #ff3e3e;
  --navyBlue: #005cbf;
  --lightGray: #d3d3d3;
  --rhino: #2e3b5a;
  --lightGrayishBlue: #bfc7dc;
  --veryLightGrayishBlue: #f5f6fa;
  --veryDarkMostlyBlackblue: #121929;
  --veryDarkLimeGreen: #01610a;
  --scienceBlue: #0372cf;
  --vividOrange: #ffbf05;
  --strongCyanLimeGreen: #00bb42;
  --pureMostlyPureBlue: #007bff;
  --greyCool: #212529;
  --pickledBluewood: #363f62;
  --eastBay: #424d73;
  --funGreen: #018630;
  --mintGreen: #75f1a1;
  --redStage: #d26109;
  --buttercup: #f0c219;
  --brightRed: #a50000;
  --outrageousOrange: #ff5934;
  --cobalt: #0047b1;
  --cerulean: #00b5fd;
  --fiord: #4b5576;
  --mirage: #172033;
  --paleNavy: #e4e7f2;
  --darkGrayishBlue: #525a5e;
  --blue-white-gray-light: rgb(1 104 181 / 57%);
  --anti-flash-white: #f2f2f2;
  --platinum-grey: #dee2e6;
  --light-primary: #9fa8b9;
  --light-rgb: 0, 0, 0, 0.2;
  --gray-light-border: rgb(255 255 255 / 12%);
  --gray-light-border-200: #e2e8f0;
  --gray-light: #768287;
  --black-pink-purple: linear-gradient(
    107.46deg,
    #ed1f68 11.65%,
    #264ac9 94.24%
  );
  --claret-blue: linear-gradient(107.46deg, #7f1734 11.65%, #0168b5 94.24%);
  --light-blue-hover: rgb(38 74 201 / 60%);
  --greatLightBlueShade: #b1b8bb;
  --light-blue: #cfd1d8;
  --text-light-blue: #bcd0f7;
  --pink-claret-btn-shadow: 0 0 0 0.2rem rgba(1, 122, 225, 0.3);
}
/* @font-face {
  font-family: "Light";
  src: local("icomoonf33b"), url("./app/fonts/icomoonf33b.ttf") format("truetype");
}
@font-face {
  font-family: "Medium";
  src: local("fontawesome-webfont"), url("./app/fonts/fontawesome-webfont.ttf") format("truetype");
} */

body,
.MuiTypography-body1 {
  /* font-family: "Medium" !important; */
  /* font: 400 0.875rem "Open Sans", sans-serif !important; */
  font: 400 0.735rem "Poppins", sans-serif !important;
}
.App {
  height: 100vh;
  display: flex;
  font-size: 0.875rem !important;
}
.required {
  color: var(--errorRed) !important;
}
.form-control.is-invalid,
.was-validated .form-control:invalid {
  border-color: var(--errorRed) !important;
}
.css-14el2xx-placeholder {
  font-size: 0.825rem !important;
  font-weight: 400 !important;
}
.ant-picker-input input {
  font-size: 13px !important;
}
.ant-picker-input input::placeholder {
  color: hsl(0, 0%, 50%) !important;
}

.icon-up:before {
  content: "\25B2";
}
.dashboardMinDiv {
  width: 100%;
}

.datewidth {
  width: 100% !important;
}
.cardheader {
  font-size: 1rem !important;
  font-weight: 500;
  padding: 0rem 0rem 1rem 0rem;
  color: var(--veryLightShadeCyanBlue);
  background: transparent;
  padding: 3px;
  margin: 10px;
}
.cardheader1 {
  font-size: 1rem !important;
  font-weight: 500;
  /* padding: 0rem 0rem 1rem 0rem; */
  color: var(--veryLightShadeCyanBlue);
  background: transparent;
  padding: 0 !important;
  /* margin: 10px; */
}
.blue_white_gray .cardheader1,
.gray_scale .cardheader1,
.blue_white_gray .cardheader,
.gray_scale .cardheader {
  color: var(--darkShadeBlue) !important;
}
.accordform {
  padding: 20px 20px 8px 20px;
  background-color: #b1b8bb !important;
  margin: 10 0;
  color: var(--darkShadeBlue) !important;
  /* background: var(--darkByzantineBlue) !important; */
  border-radius: 5px;
}

.black_pink_purple.accordform,
.claret_blue.accordform {
  background: var(--darkByzantineBlue) !important;
  border-color: var(--darkByzantineBlue) !important;
}

.blue_white_gray.accordform {
  background: var(--blue-white-gray-light) !important;
  border-color: var(--blue-white-gray-light) !important;
}

.MuiInput-underline:before {
  display: block !important;
  border-bottom: none !important;
}
.MuiInput-underline.Mui-focused:after {
  border-bottom: transparent !important;
}
.MuiDrawer-paper {
  position: inherit !important;
}
.MuiDrawer-paperAnchorDockedLeft {
  border-right: 0px !important;
}
.MuiAutocomplete-inputRoot[class*="MuiInput-root"]
  .MuiAutocomplete-input:first-child {
  font-size: 0.825rem !important;
  font-weight: 300 !important;
  color: var(--darkCharcoal) !important;
}
/* .apexcharts-xaxistooltip {
  background: var(--mediumLightShadeMagentaPink) !important;
  border: 1px solid var(--mediumLightShadeMagentaPink) !important;
  /* box-shadow: 2px 2px 10px rgb(0 0 0 / 20%); */
/* } */

.apexcharts-xaxistooltip-bottom.apexcharts-theme-dark:before {
  border-bottom-color: var(--mediumLightShadeMagentaPink);
}

.apexcharts-xaxistooltip-bottom.apexcharts-theme-dark:after {
  border-bottom-color: var(--mediumLightShadeMagentaPink);
}

.blue_white_gray .apexcharts-xaxistooltip {
  background: var(--strongBlue) !important;
  border: 1px solid var(--strongBlue) !important;
}

.blue_white_gray .apexcharts-xaxistooltip-bottom.apexcharts-theme-dark:before {
  border-bottom-color: var(--strongBlue);
}

.blue_white_gray .apexcharts-tooltip.apexcharts-theme-dark {
  background: rgb(1, 104, 181, 0.8);
  color: var(--anti-flash-white) !important;
}

.blue_white_gray
  .apexcharts-tooltip.apexcharts-theme-dark
  .apexcharts-tooltip-title {
  background: var(--strongBlue) !important;
  border-bottom: 1px solid var(--strongBlue) !important;
}

.blue_white_gray .apexcharts-xaxistooltip-bottom.apexcharts-theme-dark:after {
  border-bottom-color: var(--strongBlue);
}

.gray_scale .apexcharts-xaxistooltip {
  background: var(--blueCoolColor) !important;
  border: 1px solid var(--blueCoolColor) !important;
}

.gray_scale .apexcharts-xaxistooltip-bottom.apexcharts-theme-dark:before {
  border-bottom-color: var(--blueCoolColor);
}

.gray_scale .apexcharts-tooltip.apexcharts-theme-dark {
  background: rgb(118, 130, 135, 0.8);
}

.gray_scale
  .apexcharts-tooltip.apexcharts-theme-dark
  .apexcharts-tooltip-title {
  background: var(--blueCoolColor) !important;
  border-bottom: 1px solid var(--blueCoolColor) !important;
}

.gray_scale .apexcharts-xaxistooltip-bottom.apexcharts-theme-dark:after {
  border-bottom-color: var(--blueCoolColor);
}

.apexcharts-svg {
  background: transparent !important;
}
/* .apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title {
  background: var(--mediumLightShadeBlueMagenta) !important;
  border-bottom: 1px solid var(--mediumLightShadeBlueMagenta) !important;
  color: var(--white);
} */
.apexcharts-tooltip {
  font-size: 15px;
  margin-bottom: 4px;
  color: var(--darkShadeBlue);
}

.apexcharts-menu-item {
  color: var(--darkShadeBlue) !important;
}
.apexcharts-theme-light .apexcharts-zoomout-icon:hover svg,
.apexcharts-theme-light .apexcharts-zoomin-icon:hover svg,
.apexcharts-theme-light .apexcharts-reset-icon:hover svg,
.apexcharts-theme-light .apexcharts-menu-icon:hover svg {
  fill: #008ffb !important;
}
.headericon {
  padding: "15px 20px 15px 20px" !important;
  min-height: 66px;
  display: flex;
  font-size: 1.6rem;
  font-weight: 700;
  color: var(--darkShadeBlue);
}
.sideimage1 {
  width: 85%;
  /* max-width: 190px; */
  /* max-height: 60px; */
  margin: 0 auto !important;
}
.sideimage2 {
  max-width: 170px;
  max-height: 45px;
  /* margin: 20px auto 0 !important; */
  transition: 0.5s all;
}
.deviceId-span {
  font-weight: 700 !important;
  font-size: 14px !important;
  color: var(--white) !important;
}
.singular_note {
  font-weight: normal !important;
  font-size: 12px !important;
  color: var(--white) !important;
}
.headwelcome {
  display: flex !important;
  justify-content: flex-start;
  color: var(--grayishBlue) !important;
  font-weight: 400 !important;
}
.gray_scale.headwelcome,
.blue_white_gray.headwelcome {
  color: var(--darkShadeBlue) !important;
}
.card-gaps {
  padding-right: 12px !important;
  padding-left: 0px !important;
}
.card-gaps:last-child {
  padding-right: 0px !important;
  padding-left: 0px !important;
}
.greycolor {
  color: var(--mediumLightShadeCyanBlue) !important;
}
.sidehov:hover {
  background: var(--headerBackground);
  color: var(--white) !important;
}
.listItemIcon {
  margin-left: 5px !important;
  font-size: 1.2rem !important;
}
.sideTop {
  padding-top: 2.5px !important;
}
.itemIcon {
  display: flex !important;
  align-items: center !important;
  border-radius: 2px !important;
}
.tabhead {
  color: var(--white);
  background: var(--headerBackground);
}
.tabhead tr th {
  font-weight: 500;
}
.tabModulehead {
  color: var(--white);
  background: var(--headerBackground);
}
.tabModuleDiv {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  white-space: nowrap !important;
}
.filterForm {
  margin-left: 0.5em !important;
}
.header2 {
  align-items: initial !important;
}
.searchAlert {
  box-shadow: 0px 0pc 10px rgb(0 0 0 / 0.3) !important;
}
.modalLoader {
  z-index: 2000 !important;
}
.inputImei {
  border-bottom-left-radius: 20 !important;
  border-top-left-radius: 20 !important;
}
.inputButton {
  border-top-right-radius: 30 !important;
  border-bottom-right-radius: 30 !important;
}
.divAlign {
  text-align: center !important;
}
.buttonFilter1 {
  border-radius: 30px !important;
}
.invalid-feedback,
.validate {
  color: var(--errorRed);
  font-size: 0.725rem !important;
  font-weight: 400 !important;
  margin-top: 0.25rem !important;
  --animate-duration: 10s;
  --animate-delay: 10s;
  --animate-repeat: 1;
}
.headerDiv {
  align-items: inherit !important;
}
.cardBodycolor {
  background: var(--darkByzantineBlue) !important;
}
.userDiv1 {
  display: flex !important;
  align-items: center !important;
  font-size: 12px !important;
  flex-direction: row !important;
  width: 100% !important;
}
.videoCardheader {
  background-color: var(--darkGrayishBlue) !important;
  color: var(--white) !important;
}
.buttonColor {
  color: var(--black) !important;
  background-color: var(--white) !important;
  border: none !important;
}
.mapSearch {
  height: 485px !important;
  margin: 5px !important;
}
.cardBodycolor {
  background: var(--blueEbonyClay) !important;
}
.blue_white_gray.cardBodycolor,
.blue_white_gray.card-stats {
  background: rgb(1 104 181 / 57%) !important;
}
.blue_white_gray.devi {
  background: transparent !important;
}
.gray_scale.cardBodycolor {
  background: #b1b8bb !important;
}
.gray_scale.devi {
  background: transparent !important;
}
.blue_white_gray.cardBodycolor .MuiTypography-body1,
.gray_scale.cardBodycolor .MuiTypography-body1 {
  color: var(--darkShadeBlue) !important;
}
.cardshadow {
  box-shadow: inset 0 -1px 12px 3px rgba(255, 255, 255, .05);
  border: 1px solid rgb(255 255 255 / 12%) !important;
}
.cardBodyheight {
  padding: 1rem !important;
  height: 413.467px !important;
}
.cardTitlediv {
  width: 450px !important;
  height: 413.467px !important;
  margin: 0 auto !important;
}
.carddisplay {
  display: flex !important;
  flex-direction: row !important;
  box-shadow: inset 0 -1px 12px 3px rgba(255, 255, 255, .05);
  border: 1px solid rgb(255 255 255 / 12%) !important;
}
.cardBodywidth {
  padding: 1rem !important;
  height: 415px !important;
  width: 100% !important;
}
.cardheaderDiv {
  height: 300px !important;
  margin: 0 auto !important;
}
.divborder {
  display: flex !important;
  margin: 10px 0 20px;
  justify-content: center !important;
  align-items: center !important;
  position: relative !important;
  padding: 20px 10px 20px 0px !important;
  background: var(--darkByzantineBlue) !important;
  border-radius: 10px !important;
  box-shadow: 2px 2px 10px rgb(0 0 0 / 20%) !important;
}
.buttonShowData {
  margin-right: 10px !important;
  text-align: center !important;
}
.cardmargin {
  margin: 1% !important;
  padding: 0px !important;
  text-align: center !important;
}
.pagebutton1 {
  padding: 5px 15px !important;
  display: flex !important;
}
.button-top {
  margin-top: 10px;
}
.button-bottom {
  margin-bottom: 5px;
}
.modalpadd {
  border: none !important;
  padding-top: 80px !important;
}
.colDiv1 {
  margin: 0.3rem 0.3rem 0rem 0rem !important;
}
.colDiv2 {
  margin: 0.3rem 0.3rem 0rem 0.3rem !important;
}
.graph-colDiv2 {
  margin: 0rem 0.3rem !important;
}
.colDiv3 {
  margin: 0.3rem 0rem 0rem 0.3rem !important;
}
.mapLoc {
  height: 400px !important;
}
.mapLoc3 {
  height: 40rem !important;
  width: 100% !important;
}
.nofify1 {
  margin: 0rem 0.9rem 0rem 0rem;
  z-index: 1 !important;
}
.scrollbars1 {
  height: 200px !important;
}
.navhovCol {
  padding-left: 25px !important;
}
.navhovCol2 {
  padding: 2px 0 0 25px !important;
}
.headericon2 {
  display: flex !important;
  align-items: center;
}
.divAlign2 {
  text-align: center !important;
  margin: 0 auto !important;
  display: block !important;
}
.deviceList {
  background: var(--veryDarkMostlyBlackblue) !important;
}
.scrl2 {
  height: 300 !important;
  width: 280 !important;
  margin-left: -30px !important;
}
.videopreset {
  padding-left: 1rem !important;
}
.projectForm {
  background-color: rgb(239, 239, 239) !important;
  padding: 10px !important;
  height: 130px !important;
  overflow-y: scroll !important;
  overflow-x: hidden !important;
}
.impModuleTab tbody td {
  border: 1px solid var(--lightShadeGrey) !important;
}
.impModuleTab thead th {
  border: 1px solid var(--lightShadeGrey) !important;
}
.impModuleTab tbody tr:hover {
  background: transparent !important;
  --bs-table-hover-bg: transparent !important;
  color: none !important;
}
.footer-width {
  display: flex !important;
  width: 100% !important;
  align-items: center !important;
  justify-content: space-between !important;
}
.adminTable thead {
  display: none !important;
}
.adminTable .impModuleTab {
  text-align: inherit !important;
}
@media (min-width: 768px) {
  .adminTable tr {
    width: 47%;
    display: inline-block;
  }
}
.adminTable tr {
  min-height: 1.5rem;
  border-style: none !important;
}
.adminTable .table-hover > tbody > tr:hover {
  --bs-table-accent-bg: none;
}
.adminTable .table-hover tbody tr:hover {
  background-color: inherit !important;
}
.adminTable table {
  white-space: normal !important;
}
.adminTable .table-bordered td {
  border: 1px solid transparent !important;
}
.adminTable .table > :not(caption) > * > * {
  padding: 0.1rem 0.1rem !important;
}
.css-dwuj3p-MuiTableCell-root,
.css-1yhpg23-MuiTableCell-root {
  padding: 5px !important;
  font-size: 0.75rem !important;
}
.TrackX,
.trackY,
.ThUmBX,
.tHuMbY {
  background-color: var(--red) !important;
  color: var(--black) !important;
}
.rolescroll::-webkit-scrollbar-thumb {
  background-color: var(--vividPink) !important;
  border-radius: 0px;
  border: 1px solid transparent;
}
.rolescroll::-webkit-scrollbar-track {
  background: var(--veryDarkShadeCyanBlue) !important;
}
.roletable tbody {
  max-height: 224px !important;
  height: 100%;
  overflow-y: scroll !important;
  overflow-x: hidden !important;
}
.nti-dropdown {
  max-height: 300px !important;
  overflow-y: auto !important;
  overflow-x: hidden !important;
  padding: 15px 0px;
}
.tabmodbody tbody {
  height: 22px !important;
}
.table-fixed {
  width: 100% !important;
}
.customUpload {
  background: var(--blueCoolColor) !important;
  color: var(--white) !important;
  padding: 12px 8px !important;
  box-shadow: none !important;
  font-size: 0.725rem !important;
  font-weight: 500 !important;
  border-radius: 0px !important;
  text-transform: none !important;
}

.black_pink_purple.customUploadBtn .customUpload {
  background: var(--shadeBlue) !important;
}

.claret_blue.customUploadBtn .customUpload {
  background: var(--strongBlue) !important;
}

.blue_white_gray.customUploadBtn .customUpload {
  background: var(--strongBlue) !important;
}

.customUploadBtn {
  padding: 0 !important;
  display: flex;
  margin-left: auto;
  justify-content: flex-end;
}
.inpuLabel {
  display: flex;
  align-items: center;
  margin: 0 !important;
}
.modal-dialog {
  max-width: 45% !important;
}
.customUploadHead {
  display: inline-block !important;
  overflow: hidden;
  border: 1px solid var(--black) !important;
  width: 100% !important;
  box-sizing: border-box !important;
  background: var(--white) !important;
  border-radius: 0.25rem;
}
.black_pink_purple .customUploadHead, .claret_blue .customUploadHead {
    color: var(--text-light-blue) !important;
    box-shadow: inset 0 -1px 12px 3px rgb(255 255 255 / 6%) !important;
    border: 1px solid rgb(255 255 255 / 25%) !important;
    background: transparent !important;
}
.blue_white_gray .customUploadHead, .gray_scale .customUploadHead {
    border: 1px solid var(--light-blue) !important;
}
Form::-webkit-scrollbar {
  width: 4px;
}
Form::-webkit-scrollbar-track {
  background: var(--black);
}
Form::-webkit-scrollbar-thumb {
  border: 3px solid var(--red);
}
.table-responsive::-webkit-scrollbar {
  height: 10px !important;
}
.table-responsive::-webkit-scrollbar-track {
  background: transparent;
}
.table-responsive::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 50px;
}
.black_pink_purple .table-responsive::-webkit-scrollbar-thumb,
.claret_blue .table-responsive::-webkit-scrollbar-thumb {
  background: var(--darkByzantineBlue) !important;
}

.table-responsive {
  margin-bottom: 10px !important;
}
.boxNotes ul::-webkit-scrollbar {
  width: 4px;
}
.boxNotes ul::-webkit-scrollbar-track {
  background: transparent;
}
.boxNotes ul::-webkit-scrollbar-thumb {
  background: var(--blackWith20);
  border-radius: 50px;
}
.table-bordered td,
.table-bordered th {
  border: 1px solid var(--grayishNavy) !important;
}
.btn-outline-primary {
  color: var(--veryLightShadeCyanBlue) !important;
  box-shadow: unset !important;
}
.btn-outline-primary:hover {
  color: var(--white) !important;
  box-shadow: 0 0 0 0.2rem rgb(1 122 225/30%) !important;
}
.table-hover tbody tr:hover {
  background: var(--darkShadeBlue);
  color: var(--veryLightShadeCyanBlue) !important;
}
.hoverStyle {
  color: var(--veryLightShadeCyanBlue) !important;
  background: var(--mirage) !important;
  --bs-table-hover-color: none !important;
}
.gray_scale.hoverStyle,
.blue_white_gray.hoverStyle {
  color: var(--darkShadeBlue) !important;
  background: var(--white) !important;
  --bs-table-hover-color: none !important;
}
.gray_scale.hoverStyle .MuiSvgIcon-root,
.blue_white_gray.hoverStyle .MuiSvgIcon-root {
  fill: var(--darkShadeBlue) !important;
}

.table-hover tbody .gray_scale:hover,
.table-hover tbody .blue_white_gray:hover {
  color: var(--darkShadeBlue) !important;
  background: #fafafa !important;
  --bs-table-accent-bg: none !important;
}

.table-bordered .gray_scale td,
.table-bordered .blue_white_gray td {
  border-color: #e6e9f0 !important;
}

.table-bordered .gray_scale-bg th {
  border-color: #e6e9f0 !important;
}

.table-bordered .blue_white_gray-bg th {
  border-color: #e6e9f0 !important;
}

.moduleRow:hover td {
  color: var(--bigStone) !important;
}
.btn-outline-secondary {
  color: var(--veryLightShadeCyanBlue);
  box-shadow: unset !important;
}
.btn-outline-secondary:hover {
  color: var(--white) !important;
  background: var(--cyanBlue) !important;
}
.copyrightfooter {
  border-radius: 5px;
  color: var(--white);
  background: var(--headerBackground);
  font-size: 12px;
  box-shadow: 2px 2px 10px rgb(0 0 0/20%);
}
.copyrightText {
  text-align: left;
}

.scr::-webkit-scrollbar {
  width: 20px;
}

.scr::-webkit-scrollbar-track {
  background-color: transparent;
}

.scr::-webkit-scrollbar-thumb {
  background-color: #a7b9bc;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

.scr::-webkit-scrollbar-thumb:hover {
  background-color: #2a3234ba;
}
.App1 {
  width: 100%;
  height: 100%;
  display: flex;
}
.App1.black_pink_purple,
.App1.claret_blue {
  background-attachment: fixed !important;
  background: url("./app/assets/png/dark-bg.png");
  background-size: cover !important;
}
.App1.black_pink_purple:before,
.App1.claret_blue:before {
  position: absolute;
  inset: 0;
  z-index: 0;
  content: '';
  background-color: rgb(4 19 36 / 93%);
}
.tableArrow {
  width: 4.6rem !important;
  background: url(./app/assets/png/icon-select-white.png) no-repeat scroll !important;
  background-size: 15px 15px !important;
  background-position: 80% 50% !important;
  /* background-color: transparent !important; */
  /* cursor: pointer; */
  color: var(--veryLightShadeCyanBlue) !important;
}
.tableArrow option {
  color: black !important;
}
.Sidebar {
  height: 100vh;
  position: fixed;
  top: 0;
  z-index: 1000;
  box-shadow: 20px 0 20px -20px var(--columbia-blue);
  border-right: 1px solid rgb(255 255 255 / 12%) !important;
}
.black_pink_purple.Sidebar,
.claret_blue.Sidebar {
  /* box-shadow: none !important; */
  box-shadow: 0 -1px 12px 2px rgba(255, 255, 255, .05) !important;
}
.gray_scale.Sidebar,
.blue_white_gray.Sidebar {
  background: var(--white) !important;
  border: none !important;
  box-shadow: 20px 0 12px -20px var(--columbia-blue) !important;
}
.Sidebar:hover {
  width: 250px;
  transition: left 0.3s ease, width 0.3s ease;
}
.Sidebar:hover .main {
  max-width: calc(100% - 250px);
}
.Sidebar .MuiListItemText-root {
  flex-grow: 1;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex-shrink: 1;
  overflow: hidden;
  display: none;
}
.Sidebar:hover .MuiListItemText-root {
  display: flex;
}
.black_pink_purple.Sidebar .MuiPaper-root,
.claret_blue.Sidebar .MuiPaper-root {
  background-color: transparent !important;
}
.sidemodal {
  background: var(--veryDarkShadeBlue);
  height: 60px;
  padding: 6px 0px 0px 0px;
  display: flex;
  align-items: center;
}
.sidemodal h6 {
  color: var(--white);
  letter-spacing: 0.5px;
}
.sidelist {
  height: 289px;
}
.sidedata {
  padding: 5px 15px 5px 15px;
  color: var(--white);
  border-bottom: 1px dashed var(--veryLightShadeGray);
  display: flex;
  justify-content: space-between;
}
.scrl ul h7::before {
  content: " - ";
  margin: 0 5px 0 0;
}
.sidedata h7 {
  font-size: 13px;
  line-height: 18px;
}
.sidedata span {
  display: flex;
  height: 12px;
  width: 12px;
  border-radius: 50px;
  text-align: left;
  float: right;
}
.sidedata span.green {
  background: var(--mediumDarkShadeGreen) !important;
}
.sidedata span.red {
  background: var(--mediumDarkShadeRed) !important;
}
.toggle {
  width: 250px;
  transition: left 0.3s ease, width 0.3s ease;
}
.toggleBtn .checkbox {
  display: none !important;
}
.css-dmmspl-MuiFormGroup-root {
  flex-direction: row !important;
}
.toggle .MuiListItemText-root {
  display: flex;
}
.hide {
  width: 60px;
  height: 100%;
  transition: left 0.3s ease, width 0.3s ease;
}
.Content {
  display: flex;
  width: 100%;
  width: calc(100% - 60px);
  flex-direction: column;
  margin-left: 60px;
  transition: all 0.3s ease;
  overflow: auto;
  margin-top: 66px;
  height: calc(100vh - 66px);
  z-index: 1;
}
.shrink {
  width: calc(100% - 250px);
  margin-left: 250px;
  transition: all 0.3s ease;
}
.head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 66px;
  width: 100%;
  padding: 0.475rem 1.5rem 0.475rem 1.5rem;
  z-index: 2;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: initial;
  min-height: 66px;
  width: 100%;
}
.header-sticky-toggle-on {
  position: fixed;
  top: 0;
  width: calc(100% - 250px);
  z-index: 9;
}
.header-sticky-toggle-off {
  position: fixed;
  top: 0;
  width: calc(100% - 60px);
  z-index: 9;
}
.HeaderRight {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
}
.HeaderRightfield {
  z-index: 999;
  margin-right: auto;
}
.headDrop {
  width: 180px !important;
  font-size: 0.875rem !important;
  font-weight: 400 !important;
  margin: 6px 10px !important;
}

.MuiSvgIcon-root.header-icons {
    width: 2.1rem !important;
    height: 2.1rem !important;
    fill: var(--white) !important;
    transition: all ease 50ms !important;
    position: relative;
    padding: .33rem;
    border-radius: 5px;
    cursor: pointer;
}
.header-icons-link {
  filter: drop-shadow(0 2px 5px var(--white));
  display: flex;
  align-items: center;
  padding: 1rem .35rem;
}
.header-icons-btn {
  filter: drop-shadow(0 2px 5px var(--white));
  border: 1px solid var(--gray-light-border);
  cursor: pointer;
  width: 38px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.header-icons:hover {
    background: rgb(var(--light-rgb));
}

.blue_white_gray .header-icons-btn .header-icons:hover, 
.gray_scale .header-icons-btn .header-icons:hover {
    background: #f3f6f8 !important;
}

.blue_white_gray .header-icons-btn,
.gray_scale .header-icons-btn { 
  border: 1px solid var(--lightShadeBlue);
}

.blue_white_gray .header-icons-btn .header-icons, .gray_scale .header-icons-btn .header-icons {
  fill: #808793 !important;
}
.blue_white_gray .header-icons-btn .header-icons .spinner-border-sm.text-light, .gray_scale .header-icons-btn .header-icons .spinner-border-sm.text-light {
  color: #808793 !important;
}

.spinner-border-sm {
  border-width: 2px !important;
}

.css-4ljt47-MenuList {
  font-size: 0.875rem !important;
  font-weight: normal !important;
  /* max-height: none !important; */
}
.headDrop .css-1n7v3ny-option {
  background: var(--primary) !important;
}
.vd-select-dropdown__option,
.vd-select-dropdown__option--is-focused {
  background-color: transparent !important;
  color: var(--black) !important;
  font-size: 13px !important;
  font-weight: 400 !important;
  text-transform: capitalize !important;
}
.vd-select-dropdown__option:hover {
  background-color: var(--pureMostlyPureBlue) !important;
  color: var(--white) !important;
}
.vd-select-dropdown__option.vd-select-dropdown__option--is-selected {
  background-color: var(--pureMostlyPureBlue) !important;
  color: var(--white) !important;
}
.vd-select-dropdown__single-value {
  font-size: 13px !important;
  font-weight: 400 !important;
  text-transform: capitalize !important;
}
.headDrop .css-1s2u09g-control,
.headDrop .css-1pahdxg-control,
.headDrop .css-1s2u09g-control {
  border-radius: 4px !important;
  box-shadow: inset 0 -1px 12px 3px rgb(255 255 255 / 32%) !important;
  border: 1px solid rgb(255 255 255) !important;
  background: transparent !important;
  width: 100% !important;
}

.hoverDrop .css-tlfecz-indicatorContainer,
.headDrop .css-1wa3eu0-placeholder {
  color: var(--white) !important;
}

.headDrop .css-1okebmr-indicatorSeparator {
  display: none !important;
}
.headDrop .css-2613qy-menu,
.headDrop .css-yt9ioa-option {
  color: var(--black) !important;
}
.dataForm .css-yt9ioa-option {
  font-size: 0.825rem !important;
  font-weight: normal !important;
  color: var(--black) !important;
}

.css-26l3qy-menu {
  font-size: 0.825rem !important;
  font-weight: 400 !important;
}

.custom-select-style_blue_white_gray__option,
.custom-select-style_gray_scale__option,
.custom-select-style_claret_blue__option,
.custom-select-style_black_pink_purple__option {
  color: var(--black) !important;
  font-size: 13px !important;
  font-weight: 400 !important;
}
.custom-select-style__input {
  color: inherit !important;
}
.custom-select-style__input-container {
  color: hsl(0, 0%, 20%) !important;
  font-weight: 400 !important;
}
.custom-select-style__placeholder {
  font-weight: 400 !important;
}
.custom-select-style__single-value {
  font-weight: 400 !important;
}
.custom-select-style_blue_white_gray__option:hover,
.custom-select-style_gray_scale__option:hover,
.custom-select-style_claret_blue__option:hover,
.custom-select-style_black_pink_purple__option:hover,
.filter-select-style_blue_white_gray__option:hover,
.filter-select-style_gray_scale__option:hover,
.filter-select-style_claret_blue__option:hover,
.filter-select-style_black_pink_purple__option:hover {
  cursor: pointer !important;
}
.custom-select-style_blue_white_gray__option.custom-select-style_blue_white_gray__option--is-selected,
.custom-select-style_gray_scale__option.custom-select-style_gray_scale__option--is-selected,
.custom-select-style_claret_blue__option.custom-select-style_claret_blue__option--is-selected,
.custom-select-style_black_pink_purple__option.custom-select-style_black_pink_purple__option--is-selected,
.custom-select-style_black_pink_purple__input-container,
.custom-select-style_claret_blue__input-container,
.custom-select-style_gray_scale__input-container,
.custom-select-style_blue_white_gray__input-container,
.custom-select-style_gray_scale__single-value,
.custom-select-style_gray_scale__indicator,
.custom-select-style_claret_blue__single-value,
.custom-select-style_claret_blue__indicator,
.custom-select-style_black_pink_purple__single-value,
.custom-select-style_black_pink_purple__indicator,
.custom-select-style_blue_white_gray__single-value,
.custom-select-style_blue_white_gray__indicator {
  color: var(--white) !important;
}

.headerSearch {
  color: var(--veryLightShadeCyanBlue) !important;
  font-weight: 400 !important;
  font-size: 0.875rem !important;
  border-bottom-left-radius: 20px !important;
  border-top-left-radius: 20px !important;
  border-bottom-right-radius: 20px !important;
  border-top-right-radius: 20px !important;
  border: 1px solid var(--white) !important;
  position: relative !important;
  background: var(--white) !important;
  overflow: hidden !important;
}
.css-1laqsz7-MuiInputAdornment-root {
  height: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
}
.css-1laqsz7-MuiInputAdornment-root {
  padding: 0 !important;
  margin: 0 !important;
}
.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
  border: none !important;
  display: flex !important;
  padding: 0px !important;
  margin: 0rem !important;
  font-size: 0.875rem !important;
}
.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 6.6px 0px 6.6px 7px !important;
  margin: 0rem !important;
}
.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border: none !important;
}
.headerselectproject {
  width: 210px;
  border-radius: 50px;
  color: var(--darkShadeBlue) !important;
  background: var(--white) !important;
  margin: 6px 10px;
  padding: 2px 10px 0px 10px;
  border: 1px solid var(--lightShadeGrey);
  font-size: 0.875rem !important;
  font-weight: 400 !important;
}
.headerselectproject
  .MuiAutocomplete-inputRoot[class*="MuiInput-root"]
  .MuiAutocomplete-input:first-child {
  font-size: 0.875rem !important;
  color: var(--darkCharcoal) !important;
}
.main {
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  height: 100%;
  z-index: 0;
}
.mainHeader,
.mainFooter {
  width: 100%;
  height: 20px;
  background-color: var(--blue);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-top: 20px;
}
.mainContent {
  display: flex;
  flex: 1;
  margin: 0;
  padding: 1.5rem 0rem 0 0rem;
}

.footer-section {
  padding: 1.5rem 0.9rem;
  z-index: -1;
}

.menubutton {
  background: transparent;
  border: none;
  border-radius: 30px;
  width: 48px;
  height: 48px;
  padding: 0px 0px 0px 0px;
  transition: all 0.3s ease-in-out;
}
.menubutton:hover {
  border-radius: 30px;
  width: 48px;
  height: 48px;
  padding: 0px 0px 0px 0px;
  background: url("./app/assets/svg/lines.svg"), var(--cyanBlue) no-repeat;
  background-size: 150%;
  background-position: center center;
}

.gray_scale-bg .menubutton:hover {
  background: url("./app/assets/svg/lines.svg"), #b1b8bb no-repeat;
}

.menuIcon .MuiSvgIcon-root {
  font-size: 25px !important;
  font-weight: 600 !important;
  fill: var(--white) !important;
}
.content {
  padding: 24px;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.count-label {
  font-size: 10px;
  text-align: center;
  display: block;
  color: #fff;
  border: 1px solid var(--white);
  background: var(--mediumLightShadeBlueMagenta);
  top: 12px;
  width: fit-content;
  height: auto;
  right: 4px;
  padding: 0px 4px;
  border-radius: 30px;
  animation: pulse-red 2s infinite;
  animation-duration: 0.9s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-out;
  position: absolute;
  cursor: pointer;
}
.ball-clip-rotate-min_la-ball-clip-rotate__3oH4Z > div {
  animation: ball-clip-rotate-min_ball-clip-rotate__1prII 0.75s linear infinite !important;
  /* border-right-color: var(--loadWhite);
    border-left-color: var(--loadWhite);
    border-top-color: var(--loadWhite); */
  border-bottom-color: var(--cyanBlue);
}
.ball-clip-rotate-min_la-ball-clip-rotate__3oH4Z.ball-clip-rotate-min_la-2x__1OACW
  > div {
  width: 4rem !important;
  height: 4rem !important;
  border-width: 4px !important;
}
.table-loader .styles_LoaderContainer__2QZeQ div br {
  display: none;
}
/* .css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop{
    background-color: rgba(255, 255, 255, 0.8) !important;
  } */
.blue_white_gray .styles_WrapperUi__2TxXl, .gray_scale .styles_WrapperUi__2TxXl {
  background-color: rgba(255, 255, 255, 0.7) !important;
}

@keyframes pulse-red {
  0% {
    box-shadow: 0 0 0 0 rgb(249 153 153 / 70%);
  }
  70% {
    box-shadow: 0 0 0 10px rgb(249 153 153 / 0%);
  }
  100% {
    box-shadow: 0 0 0 0 rgb(249 153 153 / 0%);
  }
}
.dropdown-toggle:empty::after {
  display: none;
}
.app-actions {
  display: flex;
  float: right;
}
.app-actions button {
  box-shadow: inset 0 -1px 12px 3px rgba(255, 255, 255, .05);
  color: var(--grayishBlue);
  border: 1px solid var(--gray-light-border);
  display: flex;
  align-items: center;
  font-size: 0.75rem;
  border-radius: 30px;
  margin: 0 5px 0 0;
  padding: 0.35rem 0.75rem;
  background: transparent;
}
.popimptbtn {
  padding: 0.375rem 0.75rem !important;
}
.app-actions .change1:hover {
  color: var(--white);
}
.app-actions .change1:focus {
  border: 1px solid var(--shadeBlue);
}
.app-actions .change1:focus {
  content: "";
  background-image: url("./app/assets/svg/lines.svg");
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-size: 150%;
  background-position: center center;
}
.app-actions .change1.active {
  background: var(--blueCoolColor);
  color: var(--white);
  background-image: url("./app/assets/svg/lines.svg");
  background-size: 150%;
  background-position: center center;
}
.app-actions .black_pink_purple.change1.active {
  background: rgb(38 74 201 / 60%);
  color: var(--white);
  background-image: url("./app/assets/svg/lines.svg");
  background-size: 150%;
  border: 1px solid var(--shadeBlue) !important;
  background-position: center center;
  box-shadow: none !important;
}
.app-actions .claret_blue.change1.active {
  background: rgb(1 104 181 / 60%);
  color: var(--white);
  background-image: url("./app/assets/svg/lines.svg");
  background-size: 150%;
  border: 1px solid var(--strongBlue) !important;
  background-position: center center;
  box-shadow: none !important;
}
.app-actions .blue_white_gray.change1.active {
  background: var(--strongBlue);
  color: var(--white);
  background-image: url("./app/assets/svg/lines.svg");
  background-size: 150%;
  border: 1px solid var(--strongBlue) !important;
  background-position: center center;
}

.app-actions .gray_scale.change1.active {
  background: var(--blueCoolColor);
  border-color: var(--blueCoolColor);
  background-image: url("./app/assets/svg/lines.svg");
  background-size: 150%;
  background-position: center center;
  color: var(--white);
}

.app-actions .blue_white_gray.change1.active {
  background: var(--strongBlue);
  border-color: var(--strongBlue);
  background-image: url("./app/assets/svg/lines.svg");
  background-size: 150%;
  background-position: center center;
  color: var(--white);
}

.app-actions button.gray_scale,
.app-actions button.blue_white_gray {
  box-shadow: inset 0 -1px 5px 3px rgba(218, 221, 226, .4) !important;
  border: 1px solid var(--gray-light-border-200) !important;
}

.app-actions button.gray_scale:hover {
  background: var(--blueCoolColor) !important;
  color: var(--white) !important;
  background-image: url("./app/assets/svg/lines.svg") !important;
  background-size: 150% !important;
  background-position: center center !important;
}

.app-actions button.blue_white_gray:hover {
  background: var(--strongBlue) !important;
  border-color: var(--strongBlue) !important;
  color: var(--white) !important;
  background-image: url("./app/assets/svg/lines.svg") !important;
  background-size: 150% !important;
  background-position: center center !important;
}

.app-actions button.claret_blue:hover {
  background: rgb(1 104 181 / 60%);
  border: 1px solid var(--strongBlue);
  color: var(--white);
  box-shadow: none !important;
}
.app-actions button.black_pink_purple:hover {
  background: var(--light-blue-hover);
  border: 1px solid var(--shadeBlue);
  color: var(--white);
  box-shadow: none !important;
}
.app-actions {
  margin: 0 5px 0 0;
}
.app-actions .btn.active {
  background: var(--shadeBlue);
  border: 1px solid var(--shadeBlue);
  color: var(--white);
}
.app-actions .btn {
  display: flex;
  align-items: center;
}
.mod {
  background: linear-gradient(
    45deg,
    var(--mediumLightShadeBlueMagenta),
    var(--mediumLightShadeMagentaPink)
  );
  color: var(--white);
}
.modheadcont {
  font-size: 1rem !important;
  line-height: 19.2px !important;
}
.modhead .MuiSvgIcon-root {
  fill: var(--white) !important;
}
.notif {
  top: -0.9rem !important;
}
.notifMod {
  top: 2.5rem;
  right: 4px;
  position: absolute !important;
}
.modlabel {
  font-weight: 500 !important;
}
.dropinput .form-control {
  font-weight: 300;
  font-size: 0.825rem !important;
}
.css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label {
  margin-left: -6px;
  margin-right: 10px;
}
.formRow {
  align-items: baseline !important;
}
.fix-size {
  height: 100px !important;
}
.Dropdown-option.is-selected {
  font-weight: 300;
  font-size: 0.825rem !important;
}
.range {
  font-size: 0.825rem !important;
  font-weight: 400 !important;
}
.mapspan {
  text-align: center;
  font-size: 12px !important;
  color: var(--white) !important;
  display: inline-block;
  line-height: normal !important;
}
.gray_scale .mapspan {
  color: var(--black) !important;
}
.mapspan1 {
  text-align: center;
  font-size: 12px !important;
  color: transparent !important;
  display: inline-block;
  line-height: normal !important;
}
.leftdiv {
  margin-left: -20px !important;
}
.activestatus {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  position: absolute;
  margin-left: 30px;
  border: 1px solid var(--white);
  margin-top: 13px;
}
.activestatusred {
  background-color: red;
}
.activestatusorange {
  background-color: orange;
}
.activestatusgreen {
  background-color: green;
}
.rangePosition {
  padding: 0 10px !important;
}
.inpucheck {
  font-size: 0.725rem !important;
  font-weight: 600 !important;
}
.popbtn {
  font-size: 0.9rem !important;
  font-weight: 700 !important;
  color: var(--darkShadeBlue) !important;
  background: transparent !important;
  border: none !important;
}
.btnBox.btn-secondary:focus {
  box-shadow: none !important;
}
.dropinput .MuiAutocomplete-input {
  border: 1px solid var(--lightShadeBlue) !important;
}
.dropinput .css-1pahdxg-control {
  border-color: var(--veryLightShadeCyanBlue) !important;
  box-shadow: none !important;
}
.dropinput .css-1s2u09g-control {
  border-color: var(--veryLightShadeCyanBlue) !important;
}

.dropHid .css-tlfecz-indicatorContainer {
  color: transparent !important;
}
.con {
  border: 1px solid var(--veryLightShadeCyanBlue);
  box-shadow: 2px 2px 10px rgb(0 0 0 / 20%);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.multiplyIcon {
  height: 10px;
  width: 10px;
  margin: 3px;
  color: black !important;
  cursor: pointer !important;
  border-radius: 30px !important;
  display: flex;
}
.mult {
  background: var(--headerBackground);
  position: absolute;
  top: -20px;
  right: -20px;
  border-radius: 100px;
  height: 40px;
  width: 40px;
  vertical-align: middle;
  line-height: 0;
  color: var(--white);
  border: 0;
  padding: 14px;
}
.mult:hover,
mult:focus {
  opacity: 0.75 !important;
}
.modbtn {
  background: var(--mediumDarkShadeCyanBlue) !important;
  box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%) !important;
}

.form-control {
  font-size: 0.825rem !important;
  box-shadow: none !important;
  height: 38px;
  border: 1px solid var(--light-blue) !important;
}
.form-control {
  font-size: 13px !important;
  font-weight: 400 !important;
}
.claret_blue .filterForm .outtab .form-control, 
.black_pink_purple .filterForm .outtab .form-control {
    color: var(--text-light-blue) !important;
    box-shadow: inset 0 -1px 12px 3px rgb(255 255 255 / 6%) !important;
    border: 1px solid rgb(255 255 255 / 25%) !important;
    background: transparent !important;
}
.rit {
  border-left: 3px solid;
  border-image: linear-gradient(
    to right,
    var(--mediumLightShadeBlueMagenta),
    var(--mediumLightShadeMagentaPink)
  );
  border-image-slice: 1;
}
.navhov {
  background: transparent;
  width: 130px;
  height: 50px;
  color: var(--white);
  border: none;
  margin: 0px 0.5rem 0px 0px;
  padding: 0.2rem 0.2rem 0.2rem 0rem;
  font-size: 11px !important;
  position: relative;
  text-transform: none;
}

.navhov:hover {
  content: "";
  bottom: 0;
  left: 0;
  color: var(--white);
}
.navhov::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
  transition: all 0.3s;
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-top-style: solid;
  border-bottom-style: solid;
  border-top-color: rgba(255, 255, 255, 0.5);
  border-bottom-color: rgba(255, 255, 255, 0.5);
  transform: scale(0.1, 1);
}
.navhov:hover::before {
  opacity: 1;
  transform: scale(1, 1);
}
.navhov::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  transition: all 0.3s;
  background-color: rgba(255, 255, 255, 0.1);
}
.navhov:hover::after {
  opacity: 0;
  transform: scale(0.1, 1);
}
.usermainDiv {
  display: flex;
  /* flex-direction: column; */
  position: relative;
  cursor: pointer;
}
.userDropDown {
  display: none;
}
.user:hover .userDropDown {
  display: unset;
}
.profileOptionBtn {
  display: flex;
  font-size: 0.8rem;
  font-weight: 500;
  align-items: center;
  text-align: left;
  background-color: transparent;
  border: none;
  background-image: none;
}
.profileOptionBtn:hover {
  box-shadow: inset 0 -1px 12px 3px rgb(255 255 255 / 37%) !important;
  background: var(--cyanBlue);
  color: var(--white) !important;
  font-size: 0.8rem;
  font-weight: 400;
  background-image: url("./app/assets/svg/lines.svg");
  background-size: 150%;
  background-position: center center;
}
.gray_scale.profileOptionBtn:hover {
  background-color: var(--blueCoolColor) !important;
}
.blue_white_gray.profileOptionBtn:hover {
  background-color: var(--strongBlue) !important;
}
.claret_blue .profileOptionBtn:hover {
  background-color: var(--strongBlue) !important;
}
.MuiSvgIcon-root.ico {
  fill: var(--light-primary) !important;
}
.ico {
  color: var(--mainlyBlue) !important;
  font-size: 1rem;
  margin: 0.5rem !important;
}
.sig {
  font-size: 1.2rem !important;
  margin-top: 2px;
}
.profileOptionBtn:hover .ico {
  color: var(--white);
  fill: var(--white) !important;
}
.dashbtn {
  border-radius: 0%;
  transition: 0.5s all !important;
  display: flex !important;
  align-items: center !important;
  width: 100% !important;
  height: 88px !important;
  text-align: left !important;
  cursor: default !important;
  box-shadow: inset 0 -1px 12px 3px rgba(255, 255, 255, .05);
  border: 1px solid rgb(255 255 255 / 12%) !important;
}
.dashboardIconWrapper {
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  color: white;
  align-self: center;
}
.dashboardIconWrapper .MuiSvgIcon-root {
  fill: white !important;
}
.target {
  display: flex !important;
  width: 100px !important;
  margin: -0.3rem -0.3rem 1rem 0rem !important;
}
.targetSettings {
  display: flex !important;
  width: 90.5px !important;
  font-size: 0.825rem !important;
  margin: 0 0 10px 1px !important;
}
.dashHeight {
  height: 135px !important;
}
.notificationIcon {
  position: absolute !important;
}
.notificationButton {
  position: absolute;
  border: none;
  background: transparent;
  top: -1.3rem;
  right: -0.3rem;
}
.userButton {
  position: absolute;
  border: none;
  background: transparent;
  top: -1.3rem;
  right: 0rem;
}
.userButton .arrowIcon {
  color: var(--white);
  fill: var(--white) !important;
}
.notificationCard {
  border-radius: 0px 0px 5px 5px;
  margin-top: 0;
  overflow: hidden;
  width: 20rem;
  border: 0;
  top: 100%;
  z-index: 1000;
  float: left;
  min-width: 10rem; /* Added 'rem' unit */
  padding: 0.5rem 0 0 0;
  overflow: hidden;
  color: var(--greyCool);
}

.userCard {
  overflow: hidden;
  font-size: 0.75rem;
  min-height: auto;
  min-width: 12rem;
  position: relative;
  color: var(--greyCool);
  padding-bottom: 10px;
}
.userprofilediv {
  display: flex;
  padding: 10px;
  border-bottom: 1px solid var(--lightShadeBlue);
  align-items: center;
  margin-bottom: 0.5rem;
}
.userprofileimage {
  width: 44px;
  height: 44px;
  border-radius: 100px;
}
.userprofilename {
  font-size: 1rem;
  font-weight: 700;
  margin-top: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--darkShadeBlue);
}
.usertypeclass {
  color: transparent;
  background-image: var(--black-pink-purple);
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 600;
  background-clip: text;
  /* animation: rainbow-animation 100s linear infinite; */
}
.gray_scale .usertypeclass {
  background-image: linear-gradient(
    107.46deg,
    #768287 11.65%,
    #000 94.24%
  );
 }
.blue_white_gray .usertypeclass {
  background-image: linear-gradient(
    107.46deg,
    #0168b5 11.65%,
    #000 94.24%
  );
 }
.claret_blue .usertypeclass {
  background-image: var(--claret-blue);
 }
@keyframes rainbow-animation {
    to {
        background-position: 4500vh;
    }
}
.tabLink {
  text-decoration: inherit !important;
  color: inherit !important;
  width: 100% !important;
  height: 100% !important;
}
.lh-0 {
  line-height: 0;
}
.tabSet .tabLink {
  width: auto !important;
}
.user-text {
  font-weight: 400;
}
.userProfile {
  width: 12rem;
  height: 1.5rem;
  padding: 1rem 0.5rem 1rem 0.5rem;
}
.notificationRow {
  background: var(--cyanBlue);
  height: 40px;
  background-image: url("./app/assets/svg/lines.svg");
  background-position: center center;
  background-size: cover;
  position: relative;
  padding: 0px 12px;
}
.notification-text {
  color: var(--white);
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 5px;
  font-size: 0.8rem;
  font-weight: 500;
}
.nti-dropdown::-webkit-scrollbar {
  width: 7px !important;
}
.nti-dropdown::-webkit-scrollbar-thumb {
  border: 2px solid transparent !important;
}
.gray_scale.notificationRow {
  background-color: var(--blueCoolColor) !important;
}
.blue_white_gray.notificationRow {
  background-color: var(--strongBlue) !important;
}
.claret_blue.notificationRow {
  background-color: var(--strongBlue) !important;
}

.notificationCol {
  margin-left: 5px;
  display: flex;
  align-items: center;
  position: relative;
  padding: 5px !important;
  border-radius: 6px;
  margin: 4px 0px 4px 7px !important;
}
.notificationImage {
  width: 36px;
  border-radius: 50px;
  margin: 0 20px 0 0px;
}
.notificationRow1 {
  font-weight: 600;
  font-size: 0.875rem;
  margin: 0 0 3px 0 !important;
}
.notificationRow2 {
  color: var(--mainlyBlue);
  font-size: 12px;
  line-height: 150%;
}
.notificationRow3 {
  opacity: 0.4;
  font-size: 0.725rem;
  line-height: 150%;
  margin: 0 0 3px 0 !important;
}
.userDiv {
  align-items: center;
  justify-content: center;
  display: flex;
  margin: 15px -40px 0 0 !important;
}
.user1 {
  z-index: 99;
  width: 3rem;
  height: 3rem;
  border-radius: 50px;
}
.user1:hover {
  filter: drop-shadow(0 2px 5px var(--white));
}
.userIcon {
  position: absolute !important;
  bottom: 0;
  width: 100%;
  /* top: -1.1rem; */
}
.userIcon .dropdown-menu.show {
    box-shadow: 0px 0px 5px rgb(0 0 0 / 20%) !important;
}
.customInput1 {
  display: inline-block;
  margin: auto;
  width: 100%;
  flex-wrap: wrap;
}
.inputFile1 {
  display: none !important;
}
@keyframes spinning-circle {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.tex {
  font-size: 12px !important;
  color: var(--mainlyBlue);
  margin-left: 20px;
  margin-bottom: 0 !important;
  font-weight: 400 !important;
}
.num {
  font-size: 1.5rem;
  font-weight: 600;
  margin-left: 20px;
  margin-bottom: 0 !important;
}
.black_pink_purple.dashbtn .num, .claret_blue.dashbtn .num {
  color: var(--white);
}
.rad {
  margin: -0.7rem 0rem 0rem 0rem;
}
.thecont {
  display: inline-flex;
}
.MuiFormControlLabel-root {
  padding: 0 !important;
  margin: 0 !important;
}
.thetex {
  margin: 0px 10px 0px -10px;
}
.foo {
  float: right;
  width: 55px;
  height: 20px;
  margin: 7px 0px 0px 10px;
}
.grey {
  background: var(--blueCoolColor);
}
.pink {
  background: var(--headerBackground);
}
.blue {
  background: var(--strongBlue);
}
.claret {
  background: linear-gradient(
    107.46deg,
    var(--claret) 11.65%,
    var(--strongBlue) 94.24%
  );
}
.conta {
  max-width: 1400px;
  padding: 0;
  margin: 0;
}
@import url("~leaflet/dist/leaflet.css");
.leaflet-container {
  width: 100%;
  height: 100vh;
}

/* Hide the edit and save buttons */
.leaflet-draw-edit-edit,
.leaflet-draw-edit-save {
  display: none !important;
}

.tabpage {
  font-size: 1rem !important;
  font-weight: 500;
  color: var(--veryLightShadeCyanBlue);
  background: transparent;
}
.card {
  background: rgb(26 35 58 / 50%) !important;
  border-radius: 5px !important;
  margin-bottom: 0rem;
  display: flex;
  flex-direction: column;
  word-wrap: break-word;
  position: absolute;
}
.btn.btn-danger {
  z-index: 999 !important;
}
.btn-danger {
  color: var(--white);
  background: var(--mediumLightShadeRed);
}
.icon-settings {
  margin: 0.15rem 0.2rem 0rem 0rem;
  vertical-align: middle;
  width: 1rem;
}
.dashbtnroot {
  background: transparent;
}
.animate__animated .animate__delay-2s {
  animation-delay: calc(var(--animate-delay) * 2);
}
.MuiFormControlLabel-root .MuiRadio-root {
  padding: 9px !important;
}
.animate__animated {
  animation-duration: var(--animate-duration);
  animation-fill-mode: both;
}
@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }
  100% {
    opacity: 1;
    transform: translateZ(0);
  }
}
.accord {
  background: rgba(255, 255, 255, 0.1) !important;
  color: var(--veryLightShadeCyanBlue) !important;
}
.expndico {
  color: var(--veryLightShadeCyanBlue) !important;
}
.accordsummary {
  background: var(--darkByzantineBlue) !important;
  color: var(--veryLightShadeCyanBlue) !important;
  margin: 0px 0px 4px 0px !important;
}
.MuiAccordion-root:before {
  background: var(--darkByzantineBlue) !important;
  top: -4px;
  left: 0;
  right: 0;
  height: 4px;
}
.accorddetl {
  background: rgba(255, 255, 255, 0.1) !important;
  padding: 1em 1.5em !important;
}
.accorddetlcont {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  padding: 2rem 0rem 0rem 5rem;
}
.accorddetlcontrow {
  display: flex;
  width: 100%;
}
.las {
  margin: 0rem 5rem 0rem 0rem;
}
.divi {
  background: var(--white) !important;
}
.accordact {
  background: rgba(255, 255, 255, 0.1) !important;
}
.accordbut {
  color: var(--white) !important;
  background: var(--cyanBlue) !important;
  border-radius: 50px !important;
  margin: 0.25rem !important;
  padding: 5px 15px !important;
  font-size: 0.825rem !important;
  border: 1px solid transparent !important;
}
.commonBut {
  color: var(--white) !important;
  background: var(--cyanBlue) !important;
  border-radius: 50px !important;
  margin: 0.25rem !important;
  padding: 12px 25px !important;
  font-size: 0.825rem !important;
  border: 1px solid transparent !important;
}

.gray_scale-bg.accordbut:hover,
.gray_scale-bg.accordbut:focus {
  box-shadow: 0 0 0 0.2rem rgb(118 130 135/30%) !important;
}

.gray_scale-bg.btn-secondary:hover,
.gray_scale-bg.btn-secondary:focus {
  box-shadow: 0 0 0 0.2rem rgb(118 130 135/30%) !important;
}

.commonBut:hover {
  box-shadow: 0 0 0 0.2rem rgb(1 122 225/30%);
  border-color: var(--navyBlue);
}
.modal.fade.show::-webkit-scrollbar {
  width: 10px;
}
.modal.fade.show::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: none;
}
.modal.fade.show::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0);
  border-radius: 10px;
}
.modal.fade.show::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0);
}
.css-26l3qy-menu > *::-webkit-scrollbar {
  width: 5px;
}
.css-26l3qy-menu > *::-webkit-scrollbar-thumb {
  border-radius: 10px;
  /* height: 2px; */
  background-color: var(--lightShadeGrey);
}
.css-26l3qy-menu > *::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 10px;
}
Form::-webkit-scrollbar {
  width: 4px;
}
Form::-webkit-scrollbar-track {
  background: var(--black);
}
Form::-webkit-scrollbar-thumb {
  border: 3px solid var(--red);
}
.modalTop {
  border: none;
  margin-top: 80px !important;
}
.modalbtn {
  color: var(--white) !important;
  border-radius: 4px !important;
  width: 100% !important;
  padding: 5px 15px !important;
  font-size: 0.825rem !important;
  border: 1px solid transparent !important;
}
.black_pink_purple.modalbtn.btn-secondary {
  background: var(--shadeBlue) !important;
}
.claret_blue.modalbtn.btn-secondary {
  background: var(--strongBlue);
}
.gray_scale.modalbtn.btn-secondary {
  background: var(--gray-light);
}
.blue_white_gray.modalbtn.btn-secondary {
  background: var(--strongBlue);
}
.modalbtn:hover {
  box-shadow: var(--pink-claret-btn-shadow);
  border-color: var(--navyBlue);
}
.hPPIzc {
  border: 1px solid var(--lightGray);
  border-radius: 0rem !important;
  font-size: 0.825rem;
  height: 30px !important;
}
.iIqoDk .text {
  border: transparent !important;
}
.camtoggle {
  padding: 1rem;
}
.came {
  display: flex;
  justify-content: space-between;
}
.nativedrop {
  width: 25rem;
  background: var(--white);
}
.accordinp {
  background: transparent !important;
  width: 100%;
}
.accordtextinput .form-control:hover {
  border: 1px solid var(--cyanBlue) !important;
}
.accordtextinput .form-control {
  border: 1px solid var(--veryLightShadeCyanBlue) !important;
  background: transparent;
  color: var(--veryLightShadeCyanBlue) !important;
}
.HeaderRight .form-control {
  border: 1px solid transparent !important;
}
.outtab {
  font-weight: 400 !important;
  font-size: 0.775rem !important;
  color: var(--veryLightShadeCyanBlue);
}
.outtab input {
  height: calc(1.5em + 0.5rem + 2px);
}
.outtab .css-319lph-ValueContainer {
  position: unset;
}
.outtab .vd-select-dropdown__indicators {
  height: 100%;
}
.outtab .css-qc6sy-singleValue {
  width: 100% !important;
  font-size: 0.825rem !important;
  font-weight: 400 !important;
  color: var(--veryLightShadeCyanBlue) !important;
}
.outtab .css-igq18f-control,
.outtab .css-3qvjrr-control {
  border-color: var(--veryLightShadeCyanBlue);
  width: 80px;
  box-shadow: none !important;
}
.css-igq18f-control:hover {
  border-color: var(--cyanBlue) !important;
}
.outtab .css-tlfecz-indicatorContainer,
.outtab .css-1gtu0rj-indicatorContainer {
  color: var(--veryLightShadeCyanBlue) !important;
}

.gray_scale .outtab .css-tlfecz-indicatorContainer,
.gray_scale .outtab .css-1gtu0rj-indicatorContainer,
.blue_white_gray .outtab .css-tlfecz-indicatorContainer,
.blue_white_gray .outtab .css-1gtu0rj-indicatorContainer {
  color: hsl(0, 0%, 80%) !important;
}
.gray_scale .outtab .css-tlfecz-indicatorContainer:hover,
.gray_scale .outtab .css-1gtu0rj-indicatorContainer:hover,
.blue_white_gray .outtab .css-tlfecz-indicatorContainer:hover,
.blue_white_gray .outtab .css-1gtu0rj-indicatorContainer:hover {
  color: hsl(0, 0%, 60%) !important;
}
.gray_scale.btn-secondary.disabled, .btn-secondary:disabled,
.blue_white_gray.btn-secondary.disabled, .btn-secondary:disabled  {
  color: var(--white) !important;
}
.imptab {
  text-align: center;
  font-size: 0.75rem !important;
  white-space: nowrap;
  color: var(--veryLightShadeCyanBlue);
  background: var(--darkShadeBlue);
}
.imptab2 {
  color: var(--veryLightShadeCyanBlue) !important;
  background: var(--bigStone) !important;
}
.paginationButton {
  display: flex !important;
  text-align: center;
  justify-content: center;
  padding: 0.35rem 0.35rem;
  border: none !important;
  width: 30px;
  height: 30px;
  border-radius: 50px !important;
  margin: 0rem 0.3rem 0rem 0.3rem !important;
}
.radioStatus .MuiTypography-body1 {
  font-size: 0.735rem !important;
  font-weight: 500 !important;
  color: var(--darkShadeBlue) !important;
}
.radioStatusConfig .MuiFormControlLabel-label {
  color: #f2f2f2 !important;
}

.radioStatus .MuiSvgIcon-root {
  fill: var(--cyanBlue) !important;
}
.radioStatus .Mui-disabled .MuiSvgIcon-root {
  fill: rgba(0, 123, 255, 0.5) !important;
}
.radioStatus {
  flex-direction: row !important;
}
.paginationButton2 {
  color: var(--veryLightShadeCyanBlue) !important;
  border: none !important;
  border-radius: 20px !important;
  display: flex;
  text-align: center;
  justify-content: center;
  padding: 0.35rem 0.7rem !important;
  margin: 0rem 0.3rem 0rem 0rem !important;
}
.paginationButton3 {
  color: var(--veryLightShadeCyanBlue) !important;
  border: none !important;
  border-radius: 20px !important;
  display: flex;
  text-align: center;
  justify-content: center;
  padding: 0.35rem 0.7rem !important;
  margin: 0rem 0rem 0rem 0.3rem !important;
}
.tableCol {
  text-align: start !important;
}
.impModuleTab {
  text-align: center;
  font-size: 0.75rem !important;
  white-space: nowrap;
  color: var(--darkShadeBlue);
}
.imptabbody {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.rswitch {
  display: flex;
}
.rolescroll {
  width: 100% !important;
}
.tabAction {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.arrwhvr:hover:after {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  /* background: url(./app/assets/svg/menu-arrow.svg); */
  background-size: 100%;
  width: 9px;
  height: 40px;
}
.arrwhvr:hover:before {
  position: relative;
  right: 0;
  top: 0;
  background: transparent;
  background-size: 100%;
  width: auto;
  height: auto;
}
.arrwhvr:hover {
  color: var(--white) !important;
}
.navIcn {
  height: 20px !important;
}
.sidecontent .MuiList-padding {
  padding-top: 0px !important;
  height: 100vh;
}
.MuiTypography-body1 {
  font-size: 0.735rem !important;
}
.gray_scale .accordionSummary .MuiTypography-body1,
.blue_white_gray .accordionSummary .MuiTypography-body1 {
  color: var(--white) !important;
}
.MuiDrawer-paper {
  overflow: hidden !important;
}
.arrwhvr {
  transition: all 0.5s ease-in-out !important;
  padding-left: 20px !important;
  height: 100% !important;
}
.arrwhvr:hover {
  box-shadow: 0 6px 14px 2px rgb(0 0 0 / 10%);
  color: var(--white) !important;
}
.arrwhvr.active {
  background: linear-gradient(
    45deg,
    var(--mediumLightShadeBlueMagenta),
    var(--mediumLightShadeMagentaPink)
  ) !important;
  color: var(--white) !important;
  height: 100% !important;
}

.arrwhvr.active.claret_blue_hover-bg {
  background: linear-gradient(
    107.46deg,
    var(--claret) 11.65%,
    var(--strongBlue) 94.24%
  ) !important;
}

.arrwhvr.active .MuiTypography-body1 {
  color: var(--white) !important;
}
.arrwhvr.active:after {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  /* background: url(./app/assets/svg/menu-arrow.svg); */
  background-size: 100%;
  width: 9px;
  height: 40px;
}
.arrwhvr.active .MuiSvgIcon-root {
  fill: var(--white) !important;
}
.MuiPaper-elevation1 {
  box-shadow: none !important;
}
.arrwfrwd {
  background: var(--veryDarkMostlyBlackblue);
}
.arrwfrwdicn {
  font-weight: 700 !important;
  font-size: 0.9rem !important;
  color: var(--white);
}
.arrwhvr:hover .arrwfrwdicn {
  color: var(--white);
}
.arrwhvr:hover .arrwfrwdtext {
  color: var(--white);
}
.arrwfrwdtext {
  margin-left: -35px !important;
  font-size: 0.8rem !important;
  color: var(--white);
}
.deviceclrhead {
  font-size: 18px !important;
  font-weight: 700 !important;
}
.devi {
  border: none !important;
}
.SideBarItemDiv:hover {
  background: linear-gradient(
    45deg,
    var(--mediumLightShadeBlueMagenta),
    var(--mediumLightShadeMagentaPink)
  ) !important;
  color: var(--white) !important;
}
.contentActive {
  background: linear-gradient(
    45deg,
    var(--mediumLightShadeBlueMagenta),
    var(--mediumLightShadeMagentaPink)
  ) !important;
  color: white !important;
  padding-left: 20px !important;
  transition: all 0.5s ease-in-out !important;
}
.contentActive .MuiListItemText-root .MuiTypography-root {
  font-size: 0.8rem !important;
  color: var(--white) !important;
}
.contentActive .svgiconParent .MuiSvgIcon-root {
  font-size: 1.2rem !important;
  fill: var(--white) !important;
}
.contentActive .svgiconParent {
  color: white !important;
}
.subMenu:hover .MuiSvgIcon-root {
  color: white !important;
}
.subMenu.active .MuiSvgIcon-root {
  color: var(--white) !important;
}
.arrwhvr .svgiconParent .MuiSvgIcon-root {
  font-size: 1.2rem !important;
}
.arrwhvr:hover .MuiSvgIcon-root {
  fill: var(--white) !important;
}
.arrwhvr:hover .MuiTypography-body1 {
  color: var(--white) !important;
}
.contentActive .svgiconParent .MuiSvgIcon-root {
  font-size: 1.2rem !important;
}
.submenu .svgiconParent .MuiSvgIcon-root {
  font-size: 0.7rem !important;
}
.MuiSvgIcon-root {
  fill: var(--lightGrayishBlue) !important;
}
.subMenu:hover .MuiSvgIcon-root {
  color: white !important;
}
.subMenu {
  padding-left: 32px !important;
  transition: all 0.4s ease-in !important;
}
.subMenu:hover {
  box-shadow: 0 6px 14px 2px rgb(0 0 0 / 10%);
  background-color: var(--rhino) !important;
  color: white !important;
}
.subMenu:hover:after {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  background: url(./app/assets/svg/menu-arrow.svg);
  background-size: 100%;
  width: 9px;
  height: 40px;
}
.subMenu.active {
  background: var(--rhino) !important;
  color: var(--white) !important;
}
.subMenu .MuiListItemText-root .MuiTypography-top {
  font-size: 0.7rem !important;
}
.subMenu {
  padding-left: 32px !important;
  transition: all 0.4s ease-in !important;
}
.subMenu:hover {
  box-shadow: 0 6px 14px 2px rgb(0 0 0 / 10%);
  background-color: var(--rhino) !important;
  color: white !important;
}
.subMenu:hover:after {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  background: url(./app/assets/svg/menu-arrow.svg);
  background-size: 100%;
  width: 9px;
  height: 40px;
}
.subMenu.active {
  background: var(--rhino) !important;
  color: var(--white) !important;
}
.subMenu.active:after {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  background: url(./app/assets/svg/menu-arrow.svg);
  background-size: 100%;
  width: 9px;
  height: 40px;
}
.SideBarItemDiv:hover .SidebarItemDivText,
.contentActive .SidebarItemDivText {
  color: var(--white) !important;
  fill: var(--white) !important;
}
.subMenu .svgiconParent {
  min-width: 25px !important;
  height: 100% !important;
}
.svgiconParent {
  min-width: 32px !important;
  height: 100% !important;
  display: flex !important;
  align-items: center !important;
  justify-content: flex-start !important;
}
.videoModal {
  border: none !important;
  padding-top: 30px !important;
}
.MuiList-padding {
  padding-top: 0px !important;
  /* padding-bottom: 0px !important; */
}
.nondropico {
  font-size: 16px !important;
  color: var(--black) !important;
}
.dropico {
  font-size: 16px !important;
  cursor: pointer !important;
}
.dropicored {
  font-size: 16px !important;
  color: var(--mediumDarkShadeRed);
}
.dropicogreen {
  color: var(--veryDarkLimeGreen);
  font-size: 16px !important;
}
.tableModalBtn {
  border-radius: 20px !important;
  margin-right: 1px !important;
}
.tableModalBtnHeight {
  height: 1px !important;
}
.table > :not(:first-child) {
  border-top: none !important;
}
.tripsMap {
  height: 450px !important;
}
.tripsdetail {
  box-shadow: 0px 0px 3px rgb(0 0 0 / 20%) !important;
  transition: 0.5s all;
  border-radius: 5px;
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 1.2rem 1rem !important;
  border: none !important;
}
.tripsdetail.btn-secondary:focus {
  box-shadow: 2px 2px 10px rgb(0 0 0 / 20%) !important;
}
.tripsdetailheader {
  box-shadow: 2px 2px 10px rgb(0 0 0 / 20%);
  transition: 0.5s all;
  border-radius: 5px;
  display: flex;
  align-items: center;
  flex-direction: row;
  border: none !important;
}
.tripsdetailbtn {
  padding: 0.5rem 1rem !important;
}

.tripsdetailblue,
.tripsdetailred,
.tripsdetailyellow,
.tripsdetailgreen {
  background: var(--blueEbonyClay) !important;
  padding: 0 !important;
  margin: 0 !important;
}

.gray_scale .tripsdetailnum,
.blue_white_gray .tripsdetailnum {
  color: var(--black);
}

.gray_scale .tripsdetailtex,
.blue_white_gray .tripsdetailtex {
  color: var(--blueCoolColor);
}

.gray_scale .tripsDetailCardIcon,
.blue_white_gray .tripsDetailCardIcon {
  fill: var(--white) !important;
}
.gray_scale.tripsdetailblue,
.gray_scale.tripsdetailred,
.gray_scale.tripsdetailyellow,
.gray_scale.tripsdetailgreen,
.blue_white_gray.tripsdetailblue,
.blue_white_gray.tripsdetailred,
.blue_white_gray.tripsdetailyellow,
.blue_white_gray.tripsdetailgreen {
  background: var(--white) !important;
  padding: 0 !important;
  margin: 0 !important;
}
.tripsdetailblue:hover {
  /* background: var(--scienceBlue) !important; */
}
.tripsdetailred:hover {
  /* background: var(--mediumLightShadeRed) !important; */
}
.tripsdetailyellow:hover {
  /* background: var(--vividOrange) !important; */
}
.tripsdetailgreen:hover {
  /* background: var(--strongCyanLimeGreen) !important; */
}
.tripsdetail:hover,
.tripsdetailheader:hover {
  /* border-radius: 20px !important; */
  transition: 0.5s all;
  background: none;
  cursor: default !important;
}
.tripsDetailCard {
  height: 50px;
  width: 50px;
  margin-left: 15px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 15px !important;
}
.driverScoreCard {
  height: 50px;
  width: 50px;
  margin-left: 15px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 8px !important;
}
.scoreCardHead {
  height: 50px;
  width: 50px !important;
  border-radius: 50px !important;
  flex-grow: 0 !important;
}
.tripsDetailCardIcon {
  font-size: 1.4rem;
  color: var(--white);
}
.tripsDetailCardIcon.MuiSvgIcon-root {
  fill: var(--white) !important;
}
.tripsdetailnum {
  color: var(--veryLightShadeCyanBlue);
  font-weight: 500;
  font-size: 1.5rem;
}
.driverdetailsnum {
  color: var(--veryLightShadeCyanBlue);
  font-weight: 500 !important;
  font-size: 16px !important;
}
.tripsdetailtex {
  color: var(--veryLightShadeCyanBlue);
  font-weight: 400 !important;
  font-size: 0.875rem !important;
  border-bottom-left-radius: 20px !important;
  border-top-left-radius: 20px !important;
  border: 1px solid transparent !important;
}
h5 {
  color: inherit !important;
}
.recaptch-error {
  color: var(--red);
  font-size: 0.725rem !important;
}
.scorecardhoverbtn {
  margin: 1.2rem 1rem !important;
}
.tripsname {
  display: flex;
  justify-content: flex-start;
}
.tripsname .ToggleBtn .toggle-button-cover {
  margin: 0 !important;
}
.tripsdetailBtn {
  background: transparent !important;
  color: var(--darkCharcoal) !important;
  border: 1px solid transparent !important;
  margin: 0.5px 0px 0px 0px;
  font-size: 16px;
  height: 35px;
  border-bottom-right-radius: 20px !important;
  border-top-right-radius: 20px !important;
  width: fit-content;
  position: absolute;
  right: 0;
}
.tripsdetailSearch {
  color: var(--darkCharcoal) !important;
  height: 16px !important;
  margin-right: 6px !important;
  line-height: 200% !important;
}
.tripsdetailblue:hover .tripsdetailnum,
.tripsdetailblue:hover .driverdetailsnum,
.tripsdetailblue:hover .tripsdetailtex {
  /* color: var(--white) !important; */
}
.tripsdetailblue:hover .tripsDetailCardIcon {
  /* fill: var(--white) !important; */
}
.tripsdetailred:hover .tripsdetailnum,
.tripsdetailred:hover .driverdetailsnum,
.tripsdetailred:hover .tripsdetailtex {
  /* color: var(--white) !important; */
}
.tripsdetailred:hover .tripsDetailCardIcon {
  /* fill: var(--white) !important; */
}
.tripsdetailyellow:hover .tripsdetailnum,
.tripsdetailyellow:hover .driverdetailsnum,
.tripsdetailyellow:hover .tripsdetailtex {
  /* color: var(--white) !important; */
}
.tripsdetailyellow:hover .tripsDetailCardIcon {
  /* fill: var(--white) !important; */
}
.tripsdetailgreen:hover .tripsdetailnum,
.tripsdetailgreen:hover .driverdetailsnum,
.tripsdetailgreen:hover .tripsdetailtex {
  /* color: var(--white) !important; */
}
.tripsdetailgreen:hover .tripsDetailCardIcon {
  /* fill: var(--white) !important; */
}
.tripsDetailCardblue {
  background: linear-gradient(120deg, var(--cerulean) 0%, var(--cobalt) 100%);
}
.tripsDetailCardred {
  background: linear-gradient(
    120deg,
    var(--outrageousOrange) 0%,
    var(--brightRed) 100%
  );
}
.tripsDetailCardyellow {
  background: linear-gradient(
    120deg,
    var(--buttercup) 0%,
    var(--redStage) 100%
  );
}
.tripsDetailCardgreen {
  background: linear-gradient(
    120deg,
    var(--mintGreen) 0%,
    var(--funGreen) 100%
  );
}
.driverScoreDetails {
  display: block;
  width: 100%;
  position: relative;
  background-color: var(--rhino);
  text-align: center;
  padding: 10px 0;
}
.driverScoreDetails li {
  display: block;
  margin: 0 0 5px;
  text-align: center;
  font-size: 15px;
  color: var(--white);
}
.journeyData {
  position: relative;
  text-align: center;
  padding: 17px 0;
  color: var(--white) !important;
}
.journeyData li {
  display: inline-block;
  margin: 0 0 49px;
  text-align: center;
  width: 47%;
}
.journeyData li strong {
  display: block;
  font-size: 24px;
  font-weight: 600;
  margin: 0 0 5px;
}
.journeyData li span {
  display: block;
  font-weight: 300;
  font-size: 20px;
}
.headicon {
  font-size: 1.2rem !important;
}
.css-1okebmr-indicatorSeparator {
  display: none !important;
}
.dropdown-menu {
  border: none !important;
  padding: 0px !important;
  border-radius: 5px !important;
  background: rgb(255 255 255 / 97%) !important;
  backdrop-filter: blur(10px) !important;
}
.notifMod .dropdown-menu.show {
    box-shadow: 0px 0px 5px rgb(0 0 0 / 20%) !important;
}
.cardbodyheader {
  color: var(--veryLightShadeCyanBlue);
  font-size: 1rem;
  /* font-weight: 600; */
}
.blue_white_gray .cardbodyheader,
.gray_scale .cardbodyheader,
.gray_scale .outtab,
.blue_white_gray .outtab,
.blue_white_gray .outtab .css-qc6sy-singleValue,
.gray_scale .outtab .css-qc6sy-singleValue {
  color: var(--darkCharcoal) !important;
}

.cardcolor {
  border: none;
  background-color: rgb(26 35 58 / 50%) !important;
  box-shadow: inset 0 -1px 12px 3px rgba(255, 255, 255, .05);
  border: 1px solid rgb(255 255 255 / 12%) !important;
}
.cardcolor-light {
  border: 1px solid var(--gray-light-border-200) !important;
  background-color: transparent !important;
  box-shadow: inset 0 -1px 12px 3px rgba(72, 94, 144, 0.16) !important;
}

.cardcolor-light .MuiSvgIcon-root {
  fill: var(--grayishNavy) !important;
}
.commonConfigurationAccord .MuiSvgIcon-root {
  fill: var(--anti-flash-white) !important;
}

.cardcolor-light .tabpage {
  color: var(--darkShadeBlue) !important;
}

.cardcolor-light .driverdetailsnum {
  color: var(--darkShadeBlue) !important;
}

.cardcolor-light .outtab {
  color: var(--darkShadeBlue) !important;
}

.cardcolor-light .cardheader1 {
  color: var(--darkShadeBlue) !important;
}

.cardcolor-light .outtab .css-qc6sy-singleValue {
  color: var(--darkShadeBlue) !important;
}

.cardcolor-light .outtab .css-tlfecz-indicatorContainer,
.cardcolor-light .outtab .css-1gtu0rj-indicatorContainer {
  color: hsl(0, 0%, 80%) !important;
}
.cardcolor-light .outtab .css-tlfecz-indicatorContainer:hover,
.cardcolor-light .outtab .css-1gtu0rj-indicatorContainer:hover {
  color: hsl(0, 0%, 60%) !important;
}

.cardcolor-light .css-igq18f-control {
  border-color: var(--lightShadeBlue) !important;
}

.cardcolor-light .form-control {
  border-color: var(--lightShadeBlue) !important;
  box-shadow: none !important;
}

.gray_scale .cardcolor-light.form-control,
.blue_white_gray .cardcolor-light.form-control { 
  box-shadow: none !important;
}

.modalheader {
  font-size: 13px !important;
  font-weight: 500;
}
.requestIcn {
  padding: 2px 0 0 25px !important;
}
.autocompleteAssign {
  border: 1px solid var(--veryLightShadeCyanBlue) !important;
}
.autocompleteInput {
  border: none !important;
}
.autocompletenxtrow {
  width: 100% !important;
  height: 30px !important;
  background: var(--white);
  margin-right: 15px;
  margin-left: 12px;
  border: none;
  padding-left: 7px;
  box-shadow: 2px 2px 10px rgb(0 0 0 / 20%);
}
.MuiAutocomplete-option {
  font-size: 0.825rem !important;
}
.rowautocomplete {
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--grayishNavy);
  height: 110%;
  padding: 0px 5px 15px 5px;
  border-radius: 5px;
}
.rowautocompleteup {
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--grayishNavy);
  height: 110%;
  padding: 3px;
  border-radius: 5px;
}
.autocomplete {
  width: 100% !important;
  height: 30px !important;
  background: var(--white);
  margin: 15px;
  border: none;
  padding-left: 7px;
  box-shadow: 2px 2px 10px rgb(0 0 0 / 20%);
}
.autocompletehead {
  width: 100%;
  padding: 20px 20px 8px 20px;
  border-radius: 5px;
  box-shadow: 2px 2px 10px rgb(0 0 0 / 20%);
  background: var(--darkByzantineBlue) !important;
}

.gray_scale.autocompletehead,
.gray_scale.divborder,
.gray_scale.exceptautocompletehead {
  background: #b1b8bb !important;
}

.blue_white_gray.autocompletehead,
.blue_white_gray.divborder,
.blue_white_gray.exceptautocompletehead {
  background: rgb(1 104 181 / 57%) !important;
}

.driverScoreCardBody {
  padding: 10px;
  flex: 1 1 auto;
  min-height: 1px;
}
.modulcar
  .MuiAutocomplete-inputRoot[class*="MuiInput-root"]
  .MuiAutocomplete-input:first-child {
  font-size: 0.825rem;
}
.eventsBottom {
  margin-bottom: 1rem !important;
}
.eventsFont {
  font-size: 0.825rem !important;
  font-weight: normal !important;
}
.borderWidt .css-1pahdxg-control,
.borderWidt css-1pahdxg-control:hover {
  border: 1px solid var(--lightShadeBlue) !important;
  box-shadow: none !important;
}
.borderShadow {
  box-shadow: 2px 2px 10px rgb(0 0 0 / 20%) !important;
}
.dropRadius {
  border-radius: 4px !important;
}
label {
  font-size: 0.875rem !important;
  font-weight: 600 !important;
}
.ant-picker:hover,
.ant-picker-focused {
  box-shadow: none !important;
  border-color: var(--lightShadeBlue) !important;
}
.eventsdate {
  height: calc(1.5em + 0.75rem + 4px) !important;
}
.datewidthmarglft {
  margin-right: 10px;
}
.pagefrmbtn {
  font-size: 0.825rem !important;
  padding: 5px 15px !important;
  border: none !important;
  background: var(--cyanBlue) !important;
  border-radius: 50px !important;
  margin: 0px 10px 0 10px !important;
}

.claret_blue .videoGraphDiv .highcharts-legend-item text,
.black_pink_purple .videoGraphDiv .highcharts-legend-item text {
  fill: var(--white) !important;
}

.mapvehiclebtn {
  font-size: 0.825rem !important;
  padding: 5px 15px !important;
  border: none !important;
  background: var(--cyanBlue) !important;
  border-radius: 50px !important;
  margin: 0 0px 0 0px !important;
  width: 100% !important;
}
.mapvehiclerange {
  margin: 0 0 15px 0px !important;
  width: 100% !important;
  border: none;
}
.pagebtn {
  font-size: 0.825rem !important;
  padding: 5px 15px !important;
  border: none !important;
  background: var(--cyanBlue) !important;
  border-radius: 50px !important;
  margin: 2px !important;
  text-decoration: none;
}
.formBtn {
  font-size: 0.825rem !important;
  padding: 5px 15px !important;
  border: none !important;
  background: var(--cyanBlue) !important;
  border-radius: 50px !important;
}
.pagebtn:hover,
.formBtn:hover,
.pagefrmbtn:hover,
.mapvehiclebtn:hover {
  box-shadow: 0 0 0 0.2rem rgb(1 122 225/30%) !important;
  text-decoration: none;
}
.gray_scale-bg.pagebtn:hover,
.gray_scale-bg.formBtn:hover,
.gray_scale-bg.btn-secondary:focus,
.gray_scale-bg.pagefrmbtn:hover,
.gray_scale-bg.mapvehiclebtn:hover {
  box-shadow: 0 0 0 0.2rem rgb(118 130 135/30%) !important;
  text-decoration: none;
}
.pagebtnexcept {
  height: 30px;
}
.exceptautocompletehead {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 10px 10px 10px 10px;
  margin: 0px !important;
  background: var(--darkByzantineBlue) !important;
  border-radius: 5px;
  box-shadow: 2px 2px 10px rgb(0 0 0 / 20%);
  margin-left: 5%;
  margin-right: 5%;
  padding: 20px 10px !important;
}
.mapCard {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background: var(--blueEbonyClay) !important;
  width: 90% !important;
  border-radius: 5px;
  box-shadow: 2px 2px 10px rgb(0 0 0 / 20%);
  margin-left: 5%;
  margin-right: 5%;
  padding: 20px 10px !important;
}
.gray_scale.mapCard {
  background: #b1b8bb !important;
}
.blue_white_gray.mapCard {
  background: rgb(1 104 181 / 57%) !important;
}
.row,
.driverSvg {
  display: flex !important;
  align-items: center;
}
.orgtop {
  padding-top: 20px !important;
}
.popchkbtn {
  padding: 10px !important;
}
.pagebtnparnt {
  display: flex;
  justify-content: flex-end;
  padding-right: 2rem !important;
}
.pageEnd {
  display: flex;
  justify-content: flex-end;
}
.tabAll {
  margin: 0 !important;
  padding: 0 !important;
}
.shadowCard {
  border-radius: 5px !important;
  box-shadow: inset 0 -1px 12px 3px rgba(255, 255, 255, .05);
  border: 1px solid rgb(255 255 255 / 12%) !important;
  background: rgb(26 35 58 / 50%) !important;
}

.gray_scale .shadowCard .card,
.gray_scale .shadowCard .dashbtn,
.blue_white_gray .shadowCard .card,
.blue_white_gray .shadowCard .dashbtn {
  border: 1px solid var(--gray-light-border-200) !important;
  background-color: transparent !important;
  box-shadow: inset 0 -1px 12px 3px rgba(72, 94, 144, 0.16) !important;
  color: var(--darkShadeBlue) !important;
}

.gray_scale .shadowCard .dashbtn .info .tex,
.blue_white_gray .shadowCard .dashbtn .info .tex {
  color: var(--darkShadeBlue) !important;
}

.black_pink_purple.dashbtn,
.claret_blue.dashbtn {
  /* background-color: var(--blueEbonyClay) !important; */
  /* background-color: var(--veryDarkDesaturatedBlue) !important; */
  background-color: rgb(26 35 58 / 50%) !important;
}

.dashbtn.gray_scale, .dashbtn.blue_white_gray {
  border: 1px solid var(--gray-light-border-200) !important;
  background-color: transparent !important;
  box-shadow: inset 0 -1px 12px 3px rgba(72, 94, 144, 0.16) !important;
}
.gray_scale .tex, .blue_white_gray .tex {
    color: var(--darkShadeBlue) !important;
}

.gray_scale .shadowCard,
.blue_white_gray .shadowCard {
  border: 1px solid var(--gray-light-border-200) !important;
  background-color: transparent !important;
  box-shadow: inset 0 -1px 12px 3px rgba(72, 94, 144, 0.16) !important;
}

.claret_blue.shadowCard,
.black_pink_purple.shadowCard {
  background-color: rgb(26 35 58 / 50%) !important;
}

.allPage {
  padding: 0.6rem !important;
}
.allPageTab {
  padding: 0 0.6rem !important;
}
.pageTop {
  padding-top: 0.6rem !important;
}
.vidTop {
  margin-top: 0.6rem !important;
}
.scoreLeft {
  padding-left: 0.8rem !important;
  padding-right: 0.3rem !important;
}
.scoreRight {
  padding-right: 0.6rem !important;
  padding-left: 0.3rem !important;
}
.scoreRight1 {
  padding-right: 0.8rem !important;
}
.scoreMid {
  padding: 0 0.3rem !important;
}
.modmarg .pagebtn {
  margin-left: 3.5px !important;
  margin-right: 3.5px !important;
}
.mapsDrop {
  padding-left: 10px !important;
  padding-right: 10px !important;
}
.mapsDropRight {
  padding-right: 10px !important;
  padding-left: 0px !important;
}
.mapsDropLeft {
  padding-left: 10px !important;
  padding-right: 0px !important;
}
.mapsvehicleBottom {
  padding-bottom: 20px !important;
}
.mapsVehicleTop {
  padding-top: 10px !important;
}
.deletebtn {
  color: var(--pureMostlyPureBlue);
  border-radius: 5px;
  border: 1px solid var(--lightShadeBlue);
  background: var(--white);
  font-size: 0.825rem !important;
  font-weight: 600 !important;
  padding: 5px 15px !important;
}
.okbtn {
  width: 5rem !important;
  margin-right: 1rem;
  font-size: 0.825rem !important;
  font-weight: 600 !important;
  padding: 5px 15px !important;
}
.deleteModal {
  top: -30px;
  max-width: 500px !important;
}
.deletebody {
  font-size: 0.875rem !important;
  font-weight: 400 !important;
  margin-top: 10px !important;
}
.commonConfigurationAccord .MuiAccordionDetails-root {
  display: grid;
  padding: 8px 16px 16px;
}
.commonConfigurationAccord .accordionData {
  display: flex;
  justify-content: space-between;
}
.accordianDetails {
  padding: 1em 3.5em !important;
}

.commonConfigurationAccord .MuiAccordion-root:before {
  background-color: rgba(0, 0, 0, 0.12) !important;
}

.accordianAggregate {
  padding-bottom: 5px !important;
}
.accordionDescrip {
  font-size: 14px !important;
}
.accordionleft {
  margin-left: -20px;
}
.modinpulabel {
  font-size: 13px !important;
  margin-bottom: 5px !important;
}

.claret_blue .dashtabhead,
.black_pink_purple .dashtabhead,
.claret_blue .tabIniti,
.black_pink_purple .tabIniti,
.claret_blue .modinpulabel,
.black_pink_purple .modinpulabel,
.claret_blue .label-light,
.black_pink_purple .label-light {
  color: var(--veryLightShadeCyanBlue) !important;
}

.accordianRoaming {
  padding-bottom: 12px;
}
.accordianRoaming {
  padding-bottom: 12px !important;
}
.accordianAxis {
  margin-left: -2rem !important;
}
.accordionDescripexcep {
  margin-left: 0.7rem !important;
}
.accordionBold {
  font-size: 14px;
  font-weight: bold;
}
.subaccordion {
  font-size: 12px !important;
}
.accordionSlider {
  display: flex;
  justify-content: space-evenly;
  padding: 40px 20px 0 25px !important;
}
.accordianSlider .bcMPWx {
  width: 50% !important;
  float: left !important;
}
.bcMPWx {
  flex: initial !important;
}
.Mui-expanded .accordsummary {
  background: var(--fiord) !important;
}
.accordion12 .MuiNativeSelect-select.MuiNativeSelect-select,
.accordion11 .MuiNativeSelect-select.MuiNativeSelect-select,
.accordion9 .MuiNativeSelect-select.MuiNativeSelect-select {
  font-size: 0.825rem;
}
.accordionButtonContainer {
  text-align: center;
  margin: 0 auto;
  display: block;
}
.accordionButton {
  font-size: 0.825rem;
  padding: 5px 15px;
  color: var(--white);
  background: var(--blueCoolColor);
  border-radius: 50px;
  border: none;
  margin: 0.25rem;
}
.accordionButton:hover {
  box-shadow: 0 0 0 0.2rem rgb(153 153 153/30%);
}
.accordionDivider {
  margin: 0 25px 0 25px !important;
  display: flex;
  justify-content: center;
  background: var(--darkShadeBlue);
}
.divider {
  width: 1px;
  height: 60px;
  margin: 0 !important;
  background-color: var(--paleNavy);
}
.modal-footer {
  padding: 0 !important;
}
.modal-footer > * {
  margin: 0 !important;
}
.footwidth {
  width: 48% !important;
}
.accordionTrigger {
  color: hsl(0, 0%, 62%);
}
.accordionTop {
  border-top: 1px solid hsl(0, 0%, 82%);
}
.accordionSummary {
  background: var(--blueCoolColor) !important;
  color: var(--white) !important;
  box-shadow: 0px 0px 0px 1px rgb(155 155 155/30%), 0px 2px 2px rgb(0 0 0/10%);
}

.black_pink_purple .accordionSummary,
.claret_blue .accordionSummary {
  background: var(--darkByzantineBlue) !important;
  border: var(--darkByzantineBlue) !important;
  color: var(--veryLightShadeCyanBlue) !important;
  box-shadow: 2px 2px 10px rgb(0 0 0 / 20%) !important;
}

.blue_white_gray .accordionSummary {
  background: var(--strongBlue) !important;
  border: var(--strongBlue) !important;
  color: var(--white) !important;
}

.blue_white_gray .accordionSummary .MuiSvgIcon-root {
  color: var(--white) !important;
}

.black_pink_purple .accordionSummary .MuiSvgIcon-root,
.claret_blue .accordionSummary .MuiSvgIcon-root {
  color: var(--veryLightShadeCyanBlue) !important;
}

.blue_white_gray .accordionSummary .MuiSvgIcon-root,
.gray_scale .accordionSummary .MuiSvgIcon-root {
  color: var(--anti-flash-white) !important;
}

.black_pink_purple .accordianDetails,
.black_pink_purple .MuiAccordionActions-root.MuiAccordionActions-spacing,
.claret_blue .accordianDetails,
.claret_blue .MuiAccordionActions-root.MuiAccordionActions-spacing {
  background: var(--blueEbonyClay) !important;
}

.black_pink_purple .accordionDivider,
.claret_blue .accordionDivider {
  background-color: var(--darkByzantineBlue) !important;
  margin: 0px !important;
  opacity: unset !important;
}
.blue_white_gray .accordionDivider,
.gray_scale .accordionDivider {
  background-color: var(--anti-flash-white) !important;
  margin: 0px !important;
  opacity: unset !important;
}

.MuiAccordion-root.Mui-expanded {
  margin: 0 !important;
}
.MuiPaper-elevation1 {
  box-shadow: none !important;
}
.MuiAccordionSummary-content.Mui-expanded {
  margin: 0 !important;
}
.MuiAccordionSummary-root.Mui-expanded {
  min-height: 0px !important;
}
.accordionEdit {
  display: flex;
  justify-content: space-evenly;
  padding: 0px 0px 30px 0px;
}

.dangerText {
  color: var(--errorRed);
  margin: 0;
  font-size: 12px;
}

.mt-6 {
  margin-top: 4rem;
}

.settingdrop {
  border-radius: 5px !important;
  width: auto;
  font-size: 0.75rem;
  padding: 0px !important;
  margin: 0.125rem 0 0 !important;
  box-shadow: 0px 0px 3px rgb(0 0 0 / 20%);
}

.settingdrop.dropdown-menu {
  border-radius: 5px !important;
  overflow: hidden;
}

.black_pink_purple .settingdrop.dropdown-menu, .claret_blue .settingdrop.dropdown-menu {
    background: rgb(26 35 58 / 90%) !important;
    border: 1px solid rgb(255 255 255 / 25%) !important;
    box-shadow: inset 0 -1px 12px 3px rgba(255, 255, 255, .05) !important;
}

.black_pink_purple .settingdropmenu, .claret_blue .settingdropmenu {
    color: var(--text-light-blue) !important;
    border-bottom: 1px solid rgb(255 255 255 / 12%) !important;
}

.gray_scale .settingdrop .settingdropmenu:hover {
    background: var(--lightGray) !important;
    color: var(--black) !important;
}

.blue_white_gray  .settingdrop .settingdropmenu:hover {
    background: var(--strongBlue) !important;
    color: var(--white) !important;
}

.gray_scale .settingdrop .settingdropmenu:hover {
    background: var(--lightGray) !important;
    color: var(--black) !important;
}

.gray_scale .settingdrop .settingdropmenu:hover {
    background: var(--lightGray) !important;
    color: var(--black) !important;
}

.black_pink_purple .settingdropmenu:hover, .claret_blue .settingdropmenu:hover {
    background-color: var(--rhino) !important;
}

.settingdropmenu {
  text-decoration: none;
  color: var(--black);
  font-size: 12px !important;
  padding: 5px 10px !important;
  cursor: pointer;
  border-bottom: 1px solid var(--lightGray);
}
.settingdropmenu:last-child {
  border: none !important;
}
.settingdropmenu:hover {
  text-decoration: none;
  color: var(--cobalt);
}
.MuiSlider-root {
  width: 100% !important;
}
.mapbtn {
  min-width: 31%;
  margin: 0 1px 10px;
  font-size: 14px !important;
  line-height: 26px !important;
  padding: 6px 6px 10px 6px !important;
  font-weight: 600;
  display: flex;
  background: var(--pickledBluewood) !important;
  border: none !important;
  color: var(--white) !important;
  justify-content: center;
}
.maptoggle {
  margin: 0 2px 10px;
  line-height: 26px;
  width: 20% !important;
  padding: 10px 5px !important;
  text-align: center !important;
  font-weight: 500;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--pickledBluewood) !important;
  color: var(--white) !important;
  border: none !important;
  border-radius: 3px;
  background-image: url("./app/assets/svg/lines.svg") !important;
  background-size: 140% !important;
  box-shadow: inset 0 -1px 12px 3px rgb(255 255 255 / 8%);
  background-position: center center !important;
}

.black_pink_purple .maptoggle.active, 
.black_pink_purple .mapstabs button:focus:not(:focus-visible) {
    background-color: rgb(237 31 104 / 80%) !important;
}

.claret_blue .maptoggle.active, 
.claret_blue .mapstabs button:focus:not(:focus-visible) {
    background-color: rgb(127 23 52 / 80%) !important;
}

.gray_scale.maptoggle.active {
  background: var(--blueCoolColor) !important;
  color: var(--white) !important;
  background-image: url("./app/assets/svg/lines.svg") !important;
  background-size: 140% !important;
  box-shadow: inset 0 -1px 12px 3px rgb(255 255 255 / 8%);
  background-position: center center !important;
}

.gray_scale.maptoggle {
  background: #d2d2d2 !important;
  background-image: url("./app/assets/svg/lines.svg") !important;
  background-size: 140% !important;
  box-shadow: inset 0 -1px 12px 3px rgb(255 255 255 / 8%);
  background-position: center center !important;
  color: var(--darkCharcoal) !important;
}

.blue_white_gray.maptoggle.active {
  background: var(--strongBlue) !important;
  color: var(--white) !important;
  background-image: url("./app/assets/svg/lines.svg") !important;
  background-size: 140% !important;
  background-position: center center !important;
}

.blue_white_gray.maptoggle {
  background: rgb(1 104 181 / 57%) !important;
  color: var(--white) !important;
  background-image: url("./app/assets/svg/lines.svg") !important;
  background-size: 140% !important;
  background-position: center center !important;
}

.mapcardbtn .HeaderLeft {
  height: 50px;
}
.HeaderLeft {
  display: flex;
  justify-content: center;
  align-items: center;
}
.assi {
  justify-content: center !important;
  align-items: center !important;
  display: flex !important;
  white-space: nowrap !important;
}
.columImage {
  margin-left: 10 !important;
}
.filterForm {
  margin-left: 0.5em !important;
}
.vie {
  padding: 1rem !important;
}
.thumb1,
.thumb2 {
  /* height: 27vh; */
  /* margin: 0 0 6px; */
  border: 1px solid var(--grayishNavy);
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 4px 4px 2px -2px rgb(0 0 0/80%);
}
.channel1 {
  margin: 0 0.3rem 0.3rem 0.3rem !important;
}
.channel2 {
  margin: 0rem 0.3rem 0.3rem 0.3rem !important;
}
.channel3 {
  margin: 0 0rem 0.3rem 0.3rem !important;
}
.channel4 {
  margin: 0.3rem 0 0.3rem 0.3rem !important;
}
.vidthumbfirst {
  /* height: 55vh; */
  box-shadow: 4px 4px 2px -2px rgb(0 0 0/80%);
  border: 1px solid var(--grayishNavy);
  margin: 0 0.3rem 0.3rem 0rem !important;
  /* margin: 0 0 6px; */
  border-radius: 5px;
  overflow: hidden;
}
.overlay {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--eastBay);

  /* z-index: 999; */
}

.top-info {
  padding: 3px 5px !important;
  font-size: 12px;
  color: var(--anti-flash-white);
  background: var(--veryDarkMostlyBlackblue);
}

.overlayText {
  position: absolute;
  text-align: center;
  width: 100%;
}

.overlayText h2 {
  color: var(--white);
  font-size: 14px;
  text-align: center;
  font-weight: 500;
}

.boxMap {
  padding: 5px;
  border: 1px solid var(--grayishNavy);
  height: 35vh;
  background: var(--black);
  z-index: 0;
  border-radius: 5px;
  overflow: hidden;
}
.claret_blue .boxMap,
.black_pink_purple .boxMap {
  border: 1px solid rgb(255 255 255 / 12%) !important;
  background-color: rgb(26 35 58 / 50%) !important;
  box-shadow: inset 0 -1px 12px 3px rgba(255, 255, 255, .05);
}
.blue_white_gray .boxMap,
.gray_scale .boxMap {
  border: 1px solid var(--gray-light-border-200) !important;
  background-color: transparent !important;
  box-shadow: inset 0 -1px 12px 3px rgba(72, 94, 144, 0.16) !important;
}
.highcharts-container,
.highcharts-root {
  width: 100% !important;
}
/* #container {
  width: 100em !important;
  height: 244px !important;
} */
.boxNotes {
  padding: 5px;
  border: 1px solid var(--grayishNavy);
  height: 35vh;
  position: relative;
  border-radius: 5px;
}
.claret_blue  .boxNotes,
.black_pink_purple  .boxNotes {
  color: var(--text-light-blue);
  background-color: rgb(26 35 58 / 50%) !important;
  box-shadow: inset 0 -1px 12px 3px rgba(255, 255, 255, .05);
  border: 1px solid rgb(255 255 255 / 12%) !important;
}
.blue_white_gray .boxNotes,
.gray_scale .boxNotes {
  border: 1px solid var(--gray-light-border-200) !important;
  background-color: transparent !important;
  box-shadow: inset 0 -1px 12px 3px rgba(72, 94, 144, 0.16) !important;
}
.panelHeading {
  background: var(--grayishNavy);
  text-align: center;
  color: var(--white);
  padding: 5px;
  font-size: 14px;
  margin-bottom: 0.2rem;
  font-weight: 600;
}
.boxNotes ul {
  height: 20vh;
  overflow-y: scroll;
  margin: 0 0 10px;
}
.boxNotes li {
  padding: 0 8px 4px 0px;
  color: var(--white);
  list-style-type: none;
}
.boxNotes li::before {
  content: "-";
  position: relative;
}
.notesul {
  padding-left: 10px;
}
.chatBtn {
  font-size: 0.875rem;
  font-weight: 400;
  color: var(--white);
  background: var(--cyanBlue);
  border: 1px solid var(--pureMostlyPureBlue);
  border-radius: 50px;
}
.chatSend {
  float: right;
  /* margin: 5px 0; */
  width: 27%;
  color: var(--white) !important;
}
.chatInput {
  border: 1px solid var(--grayishNavy) !important;
  background: transparent !important;
  color: var(--white);
  border-radius: 0 0 5px 5px;
  font-size: 0.825rem;
  font-weight: 400;
  height: calc(1.5em + 0.75rem + 2px);
  width: 100%;
  padding: 0.375rem 0.75rem;
  outline: 0px !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.chatInput:focus {
  outline: unset !important;
}
.vidchart {
  border: 1px solid var(--grayishNavy);
  border-radius: 5px;
}
.claret_blue .vidchart, 
.black_pink_purple .vidchart {
    color: var(--text-light-blue);
    background-color: rgb(26 35 58 / 50%) !important;
    box-shadow: inset 0 -1px 12px 3px rgba(255, 255, 255, .05);
    border: 1px solid rgb(255 255 255 / 12%) !important;
}
.gray_scale .vidchart,
.blue_white_gray .vidchart {
  border: 1px solid var(--gray-light-border-200) !important;
  background-color: transparent !important;
  box-shadow: inset 0 -1px 12px 3px rgba(72, 94, 144, 0.16) !important;
}
.chatInputBar {
  width: 100%;
}
.videHeader {
  display: flex;
  font-size: 0.875rem;
  font-weight: bold;
}
.videomiddle {
  position: relative;
  margin: 10px 0 0;
}
.videofoot {
  position: relative;
}

.vid {
  display: block;
  width: 100%;
  height: 100%;
}
.vid-main {
  height: 306px;
  background: var(--black);
}
.vid-grid {
  background: var(--black);
  height: 150px;
}

.vid-grid .MuiSvgIcon-root {
  fill: #000 !important;
}
.vidchart svg.highcharts-root {
  height: 100%;
}
.searchModalBody {
  text-align: left;
}
.searchModalmap {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 0;
  margin: 24px auto;
  border: 1px solid var(--darkMidnightBlue);
  height: 500px;
  width: 100%;
}
.searchModalBooking {
  padding: 20px 0;
  width: 100%;
  margin: 0 auto;
}
.searchmodal .modal-dialog {
  width: 50rem !important;
}
.headSearch {
  border: none;
  padding-top: 40px;
  width: 100%;
  max-width: 80% !important;
}
.iconLocation {
  color: var(--black);
  font-size: 20px;
}
.dataModal {
  font-weight: 400 !important;
  height: 38px;
  font-size: 0.875rem !important;
}
.dataModalbold {
  font-size: 0.875rem;
  font-weight: bold;
}
.inputCaret {
  position: absolute !important;
  right: 5px !important;
  top: -25px !important;
}
.menuHover:hover {
  background-color: var(--pureMostlyPureBlue) !important;
  color: white;
}
.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
  font-size: 0.825rem !important;
  font-weight: normal !important;
}
.uploadIcn {
  line-height: 0 !important;
  text-wrap: nowrap !important;
}
.border-r-3 {
  border-radius: 3px !important;
}
.MuiPaper-rounded {
  border-radius: 10px !important;
  /* top: calc(100% - 556px) !important; */
  min-width: 65px !important;
  /* left: 325px !important; */
}
.pageSize {
  width: 4rem !important;
}
.pageSize .MuiSelect-selectMenu {
  color: var(--veryLightShadeCyanBlue) !important;
}
.pageSize .PrivateNotchedOutline-root-9 {
  border: 0px !important;
}
.pageSize.MuiFormControl-root {
  border: 1px solid var(--veryLightShadeCyanBlue) !important;
  border-radius: 5px !important;
  height: calc(1.5em + 0.5rem + 2px) !important;
  padding: 0 5px !important;
  font-size: 0.825rem !important;
  font-weight: 300 !important;
  display: flex !important;
  justify-content: center !important;
  align-content: center !important;
}
.pageSize .MuiSelect-iconOutlined {
  right: 0px !important;
}
.pageSize .MuiInputBase-root {
  font-size: 0.825rem !important;
  font-weight: 300 !important;
}
.pageSize .MuiOutlinedInput-inputMarginDense {
  padding: 0px !important;
}
.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root.Mui-selected {
  background-color: var(--pureMostlyPureBlue) !important;
  color: var(--white) !important;
}
.download_text {
  color: white;
}
.toggleBtnStyl {
  width: auto !important;
}
.disableField {
  color: hsl(0, 0%, 60%) !important;
  font-weight: 400 !important;
}
.css-lr9fot-singleValue {
  font-size: 0.825rem !important;
}
.css-1insrsq-control {
  background: #e9ecef !important;
}

/* Theme Colors setup */

.black_pink_purple-bg {
  background: var(--headerBackground);
}

.claret_blue-bg {
  background: linear-gradient(
    107.46deg,
    var(--claret) 11.65%,
    var(--strongBlue) 94.24%
  );
}

.gray_scale-bg {
  background: var(--blueCoolColor) !important;
}

.blue_white_gray-bg {
  background: var(--strongBlue) !important;
}

.black_pink_purple-bg-active {
  background: linear-gradient(
    45deg,
    var(--mediumLightShadeBlueMagenta),
    var(--mediumLightShadeMagentaPink)
  ) !important;
}

.claret_blue-bg-active {
  background: linear-gradient(
    107.46deg,
    var(--claret) 11.65%,
    var(--strongBlue) 94.24%
  ) !important;
}

.gray_scale-bg-active {
  background-color: var(--blueCoolColor) !important;
}

.blue_white_gray-bg-active {
  background-color: var(--strongBlue) !important;
}

.black_pink_purple_hover-bg:hover {
  background: var(--headerBackground) !important;
}

.claret_blue_hover-bg:hover {
  background: linear-gradient(
    107.46deg,
    var(--claret) 11.65%,
    var(--strongBlue) 94.24%
  ) !important;
}

.gray_scale_hover-bg:hover {
  background-color: var(--blueCoolColor) !important;
}

.gray_scale_hover-bg.active {
  background: var(--blueCoolColor) !important;
}

.blue_white_gray_hover-bg:hover {
  background: var(--strongBlue) !important;
}

.blue_white_gray_hover-bg.active {
  background: var(--strongBlue) !important;
}

.dark-bg {
  background: var(--veryDarkShadeBlue);
}

.white-bg {
  background: var(--white);
}

.sidebar-dark {
  /* background: var(--veryDarkMostlyBlackblue) !important; */
}

.sidebar-light {
  /* background: var(--veryLightGrayishBlue) !important; */
}

.text-color-dark {
  color: "#4d4d4d";
}

.text-color-dark .MuiTypography-body1 {
  color: #4d4d4d !important;
}

.text-color-dark .MuiSvgIcon-root {
  fill: #4d4d4d !important;
}

.text-color-dark.navIcn {
  fill: #4d4d4d !important;
}

.gray_scale-bg-active .svgiconParent,
.gray_scale-bg-active .MuiSvgIcon-root,
.gray_scale-bg-active .text-color-dark.navIcn,
.blue_white_gray-bg-active .svgiconParent,
.blue_white_gray-bg-active .MuiSvgIcon-root,
.blue_white_gray-bg-active .text-color-dark.navIcn,
.claret_blue-bg-active .svgiconParent,
.claret_blue-bg-active .MuiSvgIcon-root,
.claret_blue-bg-active .text-color-dark.navIcn,
.black_pink_purple-bg-active .svgiconParent,
.black_pink_purple-bg-active .MuiSvgIcon-root,
.black_pink_purple-bg-active .text-color-dark.navIcn {
  fill: var(--white) !important;
}

.gray_scale-bg-active .MuiListItemText-root .MuiTypography-root,
.blue_white_gray-bg-active .MuiListItemText-root .MuiTypography-root,
.claret_blue-bg-active .MuiListItemText-root .MuiTypography-root,
.black_pink_purple-bg-active .MuiListItemText-root .MuiTypography-root {
  color: var(--white) !important;
}

.sidebar-light .subMenu:hover {
  background: #aeb4b6 !important;
  color: var(--white) !important;
}

.sidebar-light .subMenu.active {
  background: #aeb4b6 !important;
  color: var(--white) !important;
}

.sidebar-light .subMenu:hover .MuiSvgIcon-root,
.sidebar-light .subMenu:hover .MuiTypography-body1 {
  fill: var(--white) !important;
  color: var(--white) !important;
}

.sidebar-light .subMenu.active .svgiconParent .MuiSvgIcon-root,
.sidebar-light .subMenu.active .MuiTypography-body1 {
  fill: var(--white) !important;
  color: var(--white) !important;
}

.sidebar_light_blue .subMenu:hover {
  background: rgb(1 104 181 / 57%) !important;
  color: var(--white) !important;
}

.sidebar_light_blue .subMenu.active {
  background: rgb(1 104 181 / 57%) !important;
  color: var(--white) !important;
}

.sidebar_light_blue .subMenu:hover .MuiSvgIcon-root,
.sidebar_light_blue .subMenu:hover .MuiTypography-body1 {
  fill: var(--white) !important;
  color: var(--white) !important;
}

.sidebar_light_blue .subMenu.active .svgiconParent .MuiSvgIcon-root,
.sidebar_light_blue .subMenu.active .MuiTypography-body1 {
  fill: var(--white) !important;
  color: var(--white) !important;
}

.sidebar_light_blue .sidemodal {
  background: rgb(1 104 181 / 57%) !important;
}

.sidebar-light .sidemodal {
  background: #aeb4b6 !important;
}

.sidebar-light .sidedata,
.sidebar_light_blue .sidedata {
  color: #4d4d4d !important;
}

.sidebar-light .subMenu:hover:after,
.sidebar_light_blue .subMenu:hover:after {
  background: url(./app/assets/svg/menu-arrow-light.svg);
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  background-size: 100%;
  width: 9px;
  height: 40px;
}
.sidebar-light .subMenu.active:after,
.sidebar_light_blue .subMenu.active:after {
  background: url(./app/assets/svg/menu-arrow-light.svg);
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  background-size: 100%;
  width: 9px;
  height: 40px;
}

.sidebar-light-shadow {
  box-shadow: 20px 0 20px -20px #d3d7e9;
}

.gray_scale.btn-primary.disabled,
.gray_scale.btn-primary:disabled {
  background: var(--blueCoolColor) !important;
  color: var(--white) !important;
}

.gray_scale.btn-outline-primary:hover {
  background: var(--blueCoolColor) !important;
  color: var(--white) !important;
  box-shadow: 0 0 0 0.2rem rgb(118 130 135/30%) !important;
}

.gray_scale.btn-outline-secondary:hover {
  background: var(--blueCoolColor) !important;
  color: var(--white) !important;
  box-shadow: 0 0 0 0.2rem rgb(118 130 135/30%) !important;
}

.black_pink_purple.btn-outline-secondary:hover,
.claret_blue.btn-outline-secondary:hover {
  box-shadow: 0 0 0 0.2rem rgb(1 122 225/30%) !important;
}

.blue_white_gray .btn-primary.disabled,
.blue_white_gray.btn-primary:disabled {
  background: var(--strongBlue) !important;
  color: var(--white) !important;
}

.blue_white_gray.btn-outline-primary:hover {
  background: var(--strongBlue) !important;
  color: var(--white) !important;
  box-shadow: 0 0 0 0.2rem rgb(1 122 225/30%) !important;
}

.blue_white_gray.btn-outline-secondary:hover {
  background: var(--strongBlue) !important;
  color: var(--white) !important;
  box-shadow: 0 0 0 0.2rem rgb(1 122 225/30%) !important;
}

.re-captcha {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.is-invalid .css-1s2u09g-control {
  border-color: #dc3545 !important;
}

.css-1s2u09g-control {
  border: 1px solid var(--light-blue) !important;
  box-shadow: none !important;
}

.css-1pahdxg-control {
  border: 1px solid var(--light-blue) !important;
  box-shadow: none !important;
}

.is-invalid .css-1pahdxg-control,
.requireSelect .css-1pahdxg-control {
  border-color: #dc3545 !important;
  box-shadow: none !important;
}

.css-6j8wv5-Input, .css-14el2xx-placeholder, .css-qc6sy-singleValue {
    font-size: 13px !important;
}

.uploadFileName {
  margin-top: 0;
  margin-bottom: 2.5px;
  padding-left: 5px;
  white-space: nowrap;
  overflow: hidden;
  font-weight: 400;
  text-overflow: ellipsis;
  font-size: 13px;
}

.modal-screen-table .table-responsive {
  overflow: auto;
  height: 300px;
}
.modal-screen-table .table-responsive::-webkit-scrollbar {
  width: 5px;
}

.modal-screen-table .table-responsive table {
  margin: 0px;
}

.modal-screen-table .table-responsive table thead {
  position: sticky;
  top: 0px;
}
.customcamera {
  font-size: 13px !important;
  font-weight: 500 !important;
}
.form-check {
  padding-left: 2.1em !important;
}
.form-check-input {
  border-color: var(--jordy-blue) !important;
  width: 1rem !important;
  height: 1rem !important;
  margin-top: 0em !important;
  margin-left: -2.2em !important;
}
.form-check-input:focus {
  border-color: var(--jordy-blue);
  box-shadow: none !important;
}
.warranty-radio-btn {
  width: 14px !important;
  height: 14px !important;
  margin-top: revert !important;
  margin-left: -25px !important;
}
.css-1insrsq-control,
.css-1abwfcs-control {
  background-color: #e9ecef !important;
  border: 1px solid var(--light-blue) !important;
}

.xs-loader {
  width: 11px !important;
  height: 11px !important;
  border-width: 1.5px !important;
}
.select-project {
  background-color: var(--bright-gray);
  padding: 10px;
  height: 130px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.select-project::-webkit-scrollbar {
  width: 4px;
}
.select-project::-webkit-scrollbar-track {
  background: transparent;
}
.select-project::-webkit-scrollbar-thumb {
  border: 3px solid var(--red);
}
.is-invalid .select-project {
  border: 1px solid var(--red);
}

.css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label.Mui-disabled {
  color: #6c757d !important;
}

.overflow-table .table-responsive {
  overflow: inherit;
}

.rangeHei {
  height: 38px !important;
  border-radius: 4px !important;
}

.claret_blue .rangeHei,
.black_pink_purple .rangeHei {
  box-shadow: inset 0 -1px 12px 3px rgb(255 255 255 / 6%) !important;
  border: 1px solid rgb(255 255 255 / 25%) !important;
  background: transparent !important;
}

.black_pink_purple  .rangeHei .ant-picker:hover, .claret_blue .rangeHei .ant-picker:hover, 
.claret_blue .rangeHei .ant-picker-focused, .black_pink_purple .rangeHei .ant-picker-focused {
  border: 1px solid rgb(255 255 255 / 25%) !important;
}
.black_pink_purple .rangeHei .ant-picker-input > input,
.claret_blue .rangeHei .ant-picker-input > input {
  color: var(--text-light-blue) !important;
}

.black_pink_purple .rangeHei .ant-picker-separator,
.claret_blue .rangeHei .ant-picker-separator, 
.black_pink_purple .rangeHei .ant-picker-suffix,
.claret_blue .rangeHei .ant-picker-suffix, 
.claret_blue .rangeHei .ant-picker-clear, 
.black_pink_purple .rangeHei .ant-picker-clear {
  color: rgb(128 128 128);
  background: transparent !important;
}
.rangeHei.ant-picker-range:hover .ant-picker-suffix {
  opacity: 0 !important;
}

.black_pink_purple .rangeHei.ant-picker-range .ant-picker-active-bar,
.claret_blue .rangeHei.ant-picker-range .ant-picker-active-bar {
  background: rgb(38 74 201 / 60%) !important;
}

.is-invalid.ant-picker {
  border-color: var(--errorRed) !important;
}

.is-invalid.ant-picker .ant-picker-suffix,
.is-invalid.ant-picker .ant-picker-input > input::placeholder {
  color: var(--errorRed) !important;
}
.is-invalid .custom-select-style__placeholder, .is-invalid .custom-select-style__indicator {
  color: var(--errorRed) !important;
}
/* Vehicle Journey Map CSS */
.mapLoc {
  height: 400px !important;
  z-index: 0 !important;
}
.mapLoc-high-event {
  height: 33.3vh !important;
}
.map-play-box {
  width: 25%;
  padding: 10px 10px 20px;
  background: rgb(0 0 0 / 60%);
  position: absolute;
  bottom: 0;
}

.map-play-box .speed-text,
.map-play-box .ant-slider-mark-text {
  color: var(--white);
  font-size: 11px;
}

.map-play-box .speed-text,
.map-play-box .ant-slider-mark-text {
  color: var(--white);
  font-size: 11px;
}

.blue_white_gray .map-play-box .ant-slider-track {
  background-color: var(--strongBlue) !important;
}

.claret_blue .map-play-box .ant-slider-track,
.black_pink_purple .map-play-box .ant-slider-track {
  background-color: var(--shadeBlue) !important;
}

.gray_scale .map-play-box .ant-slider-track {
  background-color: var(--blueCoolColor) !important;
}

.blue_white_gray .map-play-box .ant-slider-handle {
  border-color: var(--strongBlue) !important;
}

.claret_blue .map-play-box .ant-slider-handle,
.black_pink_purple .map-play-box .ant-slider-handle {
  border-color: var(--shadeBlue) !important;
}

.gray_scale .map-play-box .ant-slider-handle {
  border-color: var(--blueCoolColor) !important;
}

.map-play-box .ant-slider {
  margin: 4px !important;
}

.vehicle-info p {
  font-size: 11px !important;
  margin-bottom: 0px !important;
  color: var(--white);
}

.vehicle-info {
  border-bottom: 1px solid #c0c0c0;
  padding-bottom: 6px;
  margin-bottom: 6px;
}

button.play-btn {
  border: 1px solid var(--strongBlue) !important;
  padding: 3px !important;
  width: 31px !important;
  height: 31px !important;
  border-radius: 50% !important;
  background-color: #fff !important;
}

.blue_white_gray button.play-btn svg.MuiSvgIcon-root {
  fill: var(--strongBlue) !important;
}

.gray_scale button.play-btn svg.MuiSvgIcon-root {
  fill: var(--blueCoolColor) !important;
}

.claret_blue button.play-btn svg.MuiSvgIcon-root,
.black_pink_purple button.play-btn svg.MuiSvgIcon-root {
  fill: var(--shadeBlue) !important;
}

.gray_scale button.play-btn {
  border: 1px solid var(--blueCoolColor) !important;
}

.claret_blue button.play-btn,
.black_pink_purple button.play-btn {
  border: 1px solid var(--shadeBlue) !important;
}

.modtopGeoFence {
  border: none;
  max-width: 85% !important;
  margin-top: 3rem !important;
}

.leaflet-draw-actions a {
  border-left: 1px solid #aaa !important;
  color: #fff !important;
}
.leaflet-draw-actions a:hover {
  color: #0078a8 !important;
}
.multIcon {
  background: var(--black);
  border-radius: 50%;
  cursor: pointer;
  height: 16px;
  width: 16px;
  margin-left: 6px;
}

.custom-search-dropdown {
  width: 66.33%;
  margin: auto;
}

.xzzz {
  border: 1px solid var(--gray-light-border-200) !important;
  background-color: transparent !important;
  box-shadow: inset 0 -1px 12px 3px rgba(218, 221, 226, .4) !important;
  border-radius: 50px !important;
  background: transparent !important;
}

.black_pink_purple.xzzz,
.claret_blue.xzzz {
  background: transparent !important;
  box-shadow: inset 0 -1px 12px 3px rgba(255, 255, 255, .05) !important;
  border: 1px solid rgb(255 255 255 / 12%) !important;
}

.searchDash {
  border: 1px solid rgb(255 255 255 / 12%) !important;
}

.searchImage {
  padding: 0 20px;
  cursor: pointer;
}

.input-home {
  height: 30px !important;
  background: transparent !important;
  border: none !important;
  margin-left: 2px;
  outline: none !important;
  color: var(--greatLightBlueShade) !important;
}

.blue_white_gray .input-home, .gray_scale .input-home {
  color: var(--darkShadeBlue) !important;
}

.input-home::placeholder {
  color: var(--greatLightBlueShade) !important;
  font-weight: 400 !important;
  font-size: 0.875rem !important;
}

.input-home.form-control:hover {
  border: none !important;
}

.input-home:focus {
  border: none !important;
}

.search-dropdown {
  position: absolute;
  width: 100%;
  height: 300px;
  z-index: 8;
  margin-top: 5px;
  border-radius: 4px;
  padding: 5px;
  left: 0;
  overflow: auto;
  background: linear-gradient(0deg, #ffffff 0%, rgb(255 255 255 / 50%) 100%);
  backdrop-filter: blur(10px);
  border: 1px solid var(--gray-light-border);
  box-shadow: 0 .25rem .625rem rgba(20,20,20,.1);
}
.search-dropdown-border-bottom {
  border-bottom: 1px solid var(--gray-light-border);
}
.gray_scale .search-dropdown-border-bottom, .blue_white_gray .search-dropdown-border-bottom {
  border-bottom: 1px solid #e2e8f0 !important;
}

.blue_white_gray.search-dropdown, .gray_scale.search-dropdown {
  border-color: #e2e8f0 !important;
  background: rgb(255 255 255 / 90%) !important
}

.dropdown-box {
  display: flex;
  flex-wrap: wrap;
}
.search-dropdown::-webkit-scrollbar {
  height: 10px !important;
  width: 2px !important;
}

.search-dropdown::-webkit-scrollbar-thumb {
  border: 3px solid var(--cool-grey);
}

.search-dropdown .search-item {
  padding: 0px 10px;
  word-wrap: break-word;
}
.search-dropdown .search-item a {
  color: var(--black) !important;
  text-decoration: none !important;
}
.black_pink_purple.search-dropdown,
.claret_blue.search-dropdown {
  color: var(--white);
  background: linear-gradient(0deg, #272e48 35%, rgb(39 46 72 / 68%) 100%) !important;
  box-shadow: 0 0px 9px 0px #00000088 !important;
  backdrop-filter: blur(10px);
}
.black_pink_purple.search-dropdown .search-item a,
.claret_blue.search-dropdown .search-item a {
  color: var(--white) !important;
}
.search-dropdown .search-item a:hover {
  cursor: pointer;
  transition: all 0.3s;
  color: var(--cyanBlue) !important;
}
.custom-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.eye-icon-for-password {
  position: absolute;
  cursor: pointer;
  right: 10px;
  top: 7px;
}
.is-invalid.eye-icon-for-password {
  right: 30px;
}
.eye-icon-for-password svg {
  fill: var(--bigStone) !important;
  width: 20px;
}
.leaflet-control-layers-list label {
  margin-bottom: 0px !important;
  font-size: 12px !important;
  font-weight: 400 !important;
}
.leaflet-bottom.leaflet-right .leaflet-control-attribution.leaflet-control a {
  display: none;
}
.custom-map-icon {
  background-color: transparent !important;
}
.car-marker {
  z-index: 99999 !important;
}
.highlighted {
  background-color: yellow;
  font-weight: bold;
  padding: 0 !important;
}
.map-marker {
  margin-top: -24px !important;
}
/*  Width CSS */
.wh-12-px {
  width: 12px !important;
  height: 12px !important;
}
.w-5 {
  width: 5% !important;
}
.w-10 {
  width: 10% !important;
}
.w-16 {
  width: 16%;
}
.w-20 {
  width: 20% !important;
}
.w-28 {
  width: 28% !important;
}
.w-36 {
  width: 36% !important;
}
.w-40 {
  width: 40% !important;
}
.w-45 {
  width: 45% !important;
}
.w-60 {
  width: 60%;
}
.w-90 {
  width: 90% !important;
}
.w-98_5 {
  width: 98.5% !important;
}
.w-13-px {
  width: 13px !important;
}
.h-13-px {
  height: 13px !important;
}
.h-35-px {
  height: 35px !important;
}
.w-px-18 {
  width: 18px !important; 
}
.w-px-65 {
  width: 65px !important;
}
.wh-px-13 {
  width: 13px !important;
  height: 13px !important;
}
/* custom font size */
.f-13 {
  font-size: 13px !important;
}
.f-16 {
  font-size: 16px !important;
}
.f-12 {
  font-size: 12px !important;
}

.f-10 {
  font-size: 10px !important;
}

/* Space */
.mt-5-px {
  margin-top: 5px !important;
}

.closeNotif .MuiSvgIcon-root {
  color: var(--white) !important;
  fill: var(--mediumLightShadeCyanBlue) !important;
}

.gray_scale .closeNotif .MuiSvgIcon-root {
  fill: var(--blueCoolColor) !important;
}
.blue_white_gray .closeNotif .MuiSvgIcon-root {
  fill: var(--strongBlue) !important;
}

.fading-line {
  width: 100%;
  height: 1px;
  background: linear-gradient(
    to left,
    transparent 0%,
    rgba(0, 0, 0, 0.5) 50%,
    transparent 100%
  );
}

.fade-out {
  transition: opacity 0.3s ease, transform 0.3s ease;
  will-change: opacity, transform;
}

.slide-right {
  transform: translateX(100%);
  opacity: 0;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}
/* CSS for sliding animation */
.notification-item {
  will-change: transform;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.slide-up {
  transform: translateY(-100%);
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.location-popover {
  width: 270px;
  font-size: 12px !important;
  padding: 0px !important;
}

.location-popover .ant-popover-inner-content {
  padding: 3px 8px !important;
}

.location-popover .ant-popover-arrow-content {
  width: 6px;
  height: 6px;
  transform: translateX(-4.24264069px) rotate(45deg);
}

.location-popover.ant-popover-placement-left .ant-popover-arrow {
  transform: translateY(-50%) !important;
  width: 8.48528137px !important;
  height: 8.48528137px !important;
}

.location-popover .ant-popover-arrow-content::before {
  display: none;
}

.location-popover.ant-popover-placement-left .ant-popover-arrow {
  right: -8px !important;
}

.location-popover .MuiSvgIcon-root.MuiSvgIcon-colorPrimary {
  fill: var(--cyanBlue) !important;
}

.location-popover .ant-popover-inner-content a {
  color: var(--cyanBlue) !important;
  text-decoration: none;
}

.location-popover .ant-popover-inner-content a:hover {
  text-decoration: underline;
}

.gray_scale-bg.bg-transparent {
  border-color: var(--blueCoolColor) !important;
  color: var(--blueCoolColor) !important;
}
.black_pink_purple-bg.bg-transparent,
.claret_blue-bg.bg-transparent {
  border-color: var(--cyanBlue) !important;
  color: var(--cyanBlue) !important;
}
.blue_white_gray-bg.bg-transparent {
  border-color: var(--strongBlue) !important;
  color: var(--strongBlue) !important;
}

.toggleConfig {
  width: 50% !important;
  float: left !important;
  display: flex;
  min-width: 0px;
  line-height: 2rem;
}
.height-object-fit {
  height: fit-content !important;
}
.ant-modal-mask,
.ant-modal-wrap {
  z-index: 2000; /* Adjust the z-index value as needed */
}

.logo-large img {
  height: 68px;
}

.logo-small img {
  height: 54px;
}
.vidchart .highcharts-container {
  height: 35vh !important;
}
.vidchart .highcharts-credits {
  display: none !important;
}
.claret_blue .videoGraphDiv .highcharts-legend-item text,
.black_pink_purple .videoGraphDiv .highcharts-legend-item text {
  fill: var(--white) !important;
}
.claret_blue .videoGraphDiv .highcharts-no-tooltip path,
.black_pink_purple .videoGraphDiv .highcharts-no-tooltip path {
  stroke: var(--white) !important;
}
.blue_white_gray .videHeader .tabLink,
.gray_scale .videHeader .tabLink {
  color: var(--black) !important;
}

.gray_scale .boxNotes li,
.blue_white_gray .boxNotes li {
  color: var(--black) !important;
}

.blue_white_gray .chatInput,
.gray_scale .chatInput {
  color: var(--black) !important;
}

.blue_white_gray .boxNotes .panelHeading {
  background-color: var(--strongBlue);
}
.gray_scale .boxNotes .panelHeading {
  background: var(--blueCoolColor);
}

.blue_white_gray .videoGraphDiv .highcharts-button-box,
.gray_scale .videoGraphDiv .highcharts-button-box {
  fill: transparent;
  stroke: #00000036;
}
.blue_white_gray .boxMap,
.gray_scale .boxMap {
  background: transparent !important;
}

.blue_white_gray .videoGraphDiv .highcharts-menu-item:hover {
  background: var(--strongBlue) !important;
}

.gray_scale .videoGraphDiv .highcharts-menu-item:hover {
  background: var(--blueCoolColor) !important;
}

.notification-read {
  background: rgba(0, 0, 0, 0.09);
  color: #202124;
  opacity: 0.8 !important;
}
.cursor-pointer {
  cursor: pointer;
}
.mark-as-read {
  height: 34px;
  background: transparent;
  color: var(--white);
  position: relative;
  font-size: 14px !important;
  border: none;
  margin: 0px 0.5rem 0px 0px;
  text-transform: none;
}

.mark-as-read:hover {
  background: transparent;
  bottom: 0;
  left: 0;
  color: var(--white);
  font-size: 14px !important;
}

.mark-as-read::before {
  content: "";
  position: absolute;
  background: transparent;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
  transition: all 0.3s;
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-top-style: solid;
  border-bottom-style: solid;
  border-top-color: rgba(255, 255, 255, 0.5);
  border-bottom-color: rgba(255, 255, 255, 0.5);
  transform: scale(0.1, 1);
}
.mark-as-read:hover::before {
  opacity: 1;
  transform: scale(1, 1);
}
.mark-as-read::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  transition: all 0.3s;
  background-color: rgba(255, 255, 255, 0.1);
}
.mark-as-read:hover::after {
  opacity: 0;
  transform: scale(0.1, 1);
}

.notification-col {
  height: 40px;
}

.driverScoreCards {
  display: flex;
  border-radius: 5px !important;
  flex-direction: row;
  box-shadow: inset 0 -1px 12px 3px rgba(255, 255, 255, .05);
  border: 1px solid rgb(255 255 255 / 12%) !important;
}

.black_pink_purple.driverScoreCards,
.claret_blue.driverScoreCards {
  /* background: var(--veryDarkDesaturatedBlue); */
  background-color: rgb(26 35 58 / 50%) !important;
}

.blue_white_gray.driverScoreCards,
.gray_scale.driverScoreCards {
  border: 1px solid var(--gray-light-border-200) !important;
  background-color: transparent !important;
  box-shadow: inset 0 -1px 12px 3px rgba(72, 94, 144, 0.16) !important;
}

.gray_scale .accordionTrigger,
.blue_white_gray .accordionTrigger {
  color: var(--white);
}

.full-loader .styles_WrapperUi__2TxXl {
  z-index: 20000 !important;
  position: fixed;
}
.styles_WrapperUi__2TxXl {
  width: 100% !important;
}

.claret_blue button.play-btn,
.black_pink_purple button.play-btn {
  border: 1px solid var(--shadeBlue) !important;
}

.claret_blue button.play-btn svg.MuiSvgIcon-root,
.black_pink_purple button.play-btn svg.MuiSvgIcon-root {
  fill: var(--shadeBlue) !important;
}

.claret_blue .map-play-box .ant-slider-track,
.black_pink_purple .map-play-box .ant-slider-track {
  background-color: var(--shadeBlue) !important;
}
.gray_scale button.play-btn svg.MuiSvgIcon-root {
  fill: var(--blueCoolColor) !important;
}
.gray_scale button.play-btn {
  border: 1px solid var(--blueCoolColor) !important;
}

.claret_blue .map-play-box .ant-slider-dot-active,
.black_pink_purple .map-play-box .ant-slider-dot-active,
.claret_blue .map-play-box .ant-slider-dot,
.black_pink_purple .map-play-box .ant-slider-dot {
  border-color: var(--shadeBlue) !important;
}

.gray_scale .map-play-box .ant-slider-dot-active,
.gray_scale .map-play-box .ant-slider-dot {
  border-color: var(--blueCoolColor) !important;
}

.blue_white_gray .map-play-box .ant-slider-dot-active,
.blue_white_gray .map-play-box .ant-slider-dot {
  border-color: var(--strongBlue) !important;
}

.table-loader .styles_WrapperUi__2TxXl {
  position: absolute;
  height: 100%;
  z-index: 0;
}

.claret_blue.table-loader .styles_WrapperUi__2TxXl,
.black_pink_purple.table-loader .styles_WrapperUi__2TxXl {
  background: rgba(0, 0, 0, 0.75) !important;
}

.video-not-available {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #ccc; /* You can change the background color */
  color: #333; /* You can change the text color */
  font-size: 16px;
  font-weight: 500;
  text-align: center;
}

/* Admin Note Section CSS */
.notes-section {
  height: calc(100% - 70px);
  overflow: auto;
}
.notes-section::-webkit-scrollbar {
  width: 2px;
}
.notes-section::-webkit-scrollbar-track {
  background: transparent;
}
.notes-section::-webkit-scrollbar-thumb {
  border: 3px solid var(--gray);
}
.notes-box {
  position: relative;
  width: fit-content;
  margin-bottom: 5px;
  margin-left: auto;
  margin-right: 10px;
}
.user-note {
  background: var(--platinum-grey);
  padding: 5px 8px;
  border-radius: 4px;
  width: fit-content;
}
.notes-detail {
  font-size: 9px;
  margin: 0px !important;
  color: var(--light-primary);
}
.user-note::after {
  content: "";
  position: absolute;
  right: -9px;
  top: 8px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent transparent var(--platinum-grey);
}
.notes-detail.note {
  font-size: 10px;
  color: var(--black);
}
.notes-form {
  position: absolute;
  width: 100%;
  display: flex;
  left: 0;
  padding: 0px 5px;
  bottom: 5px;
  background: var(--veryLightGrayishBlue);
}
.black_pink_purple .notes-form,
.claret_blue .notes-form {
  background: var(--veryDarkDesaturatedBlue);
}

.tripActionBtns2 {
  fill: var(--claret) !important;
  cursor: pointer !important;
}
.trip-event-popup .leaflet-popup-content {
  margin: 4px 7px 7px !important;
}
.trip-event-popup a.leaflet-popup-close-button {
  top: 7px;
  right: 3px;
  padding: 0px !important;
}
.trip-event-popup .leaflet-popup-content-wrapper {
 border-radius: 4px;
}
.trip-event-popup .text-heading {
  font-weight: 600;
  font-size: 14px;
  border-bottom: 1px solid var(--light-primary);
  margin-bottom: 5px;
  padding-bottom: 3px;
}

.icon-green.MuiSvgIcon-root {
  fill: var(--mediumDarkShadeGreen) !important;
}
.icon-red.MuiSvgIcon-root {
  fill: var(--errorRed) !important;
}
.icon-blue.MuiSvgIcon-root {
  fill: var(--cyanBlue) !important;
}
.videoGraphDiv {
    width: 100% !important;
    height: 100% !important;
    background-color: transparent;
}
.text-gray {
  color: var(--blueCoolColor);
}
.black_pink_purple .notes-section.no-data, .claret_blue .notes-section.no-data {
    color: var(--blueCoolColor);
}
.boxMap .mapLoc {
    height: 33vh !important;
}

.loginLogo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100% !important;
  padding: 20px 0px 30px 0px;
}

.dms-img-event-modal {
  border: none;
  max-width: 60% !important;
}

.dms-img-event-modal .modal-body {
  padding: 5px;
}

.geoFence-event-modal {
  border: none;
  max-width: 95% !important;
}

.geoFence-event-modal .modal-body {
  padding: 5px;
}

.geoFence-event-modal .mapLoc {
  height: 470px !important;
}

ul.leaflet-draw-actions.leaflet-draw-actions-bottom li a[title="Save changes"] {
    display: none;
}

@media (min-width: 1400px) {
  .geoFence-event-modal .mapLoc {
    height: 535px !important;
  }
}

@media (min-width: 1536px) {
  .geoFence-event-modal .mapLoc {
    height: 610px !important;
  }
}

.vd-select-dropdown__menu {
  background: linear-gradient(0deg, #ffffff 0%, rgb(255 255 255 / 85%) 100%) !important;
  backdrop-filter: blur(10px) !important;
}
.black_pink_purple-bg.header-sticky-toggle-on, .claret_blue-bg.header-sticky-toggle-on,
.blue_white_gray-bg.header-sticky-toggle-on, .gray_scale-bg.header-sticky-toggle-on {
  box-shadow: 0px 0px 5px 0px rgb(0 0 0);
}
.leaflet-popup-content-wrapper {
    border-radius: 4px !important;
}
.leaflet-popup-content-wrapper, .leaflet-popup-tip {
  font-family: "Poppins", sans-serif !important;
  box-shadow: inset 0px 0px 8px 4px rgb(203 203 203) !important;
  background: #ffffffa3 !important;
  backdrop-filter: blur(10px) !important;
}
.leaflet-popup-content {
  margin: 10px !important;
}
.leaflet-container a.leaflet-popup-close-button {
  top: -8px !important;
  right: -10px !important;
  background: #fff !important;
  padding: 0px !important;
  height: 18px !important;
  font-size: 13px !important;
  border: 1px solid !important;
  width: 18px !important;
  border-radius: 3px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  line-height: 1px !important;
}

.selected-marker-end {
  width: 10px !important;
  height: 10px !important;
  background: var(--funGreen);
  border: 1px solid var(--white);
  border-radius: 50%;
}

.selected-marker-start {
  width: 10px !important;
  height: 10px !important;
  background: var(--funGreen);
  border: 1px solid var(--white);
  border-radius: 50%;
}

.selected-marker-edit {
  width: 8px !important;
  height: 8px !important;
  background: var(--cyanBlue);
  border: 1px solid var(--white);
  border-radius: 50%;
}

.video-request-tool {
  position: absolute;
  top: 80px;
  left: 12.5px;
  z-index: 8;
  display: flex;
  flex-direction: column;
}

.custom-font-tooltip .ant-tooltip-inner {
  font-size: 12px !important;
}

@media (min-width: 1600px) {
    .mapLoc-map-page .mapLoc {
      height: calc(100vh - 350px) !important;
    } 
}

.leaflet-div-icon {
  background: #fff;
  border: 2px solid var(--cyanBlue) !important;
  width: 14px !important;
  height: 14px !important;
  border-radius: 50% !important;
  margin-left: -7px !important;
  margin-top: -7px !important;
}
.map-play-custom-width {
  width: 235px !important;
}

body::-webkit-scrollbar {
  display: none;
}

body::-webkit-scrollbar-button {
  display: none;
}

.Content::-webkit-scrollbar {
  width: 8px !important;
  height: 10px !important;
}

.Content::-webkit-scrollbar-track {
  background: transparent;
}

.Content::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 50px;
  height: 50px !important;
}

.black_pink_purple .Content::-webkit-scrollbar-track,
.claret_blue .Content::-webkit-scrollbar-track {
  background: transparent !important;
}

.black_pink_purple .Content::-webkit-scrollbar-thumb,
.claret_blue .Content::-webkit-scrollbar-thumb {
  background: var(--darkByzantineBlue) !important;
}

.gray_scale .filter-select-style__option,
.blue_white_gray .filter-select-style__option,
.custom-select-style_gray_scale__option,
.custom-select-style_black_pink_purple__option,
.custom-select-style_claret_blue__option,
.custom-select-style_blue_white_gray__option,
.filter-select-style_gray_scale__option,
.filter-select-style_black_pink_purple__option,
.filter-select-style_claret_blue__option,
.filter-select-style_blue_white_gray__option {
    border-bottom: 1px solid var(--gray-light-border-200) !important;
}

.claret_blue .filter-select-style__menu, 
.black_pink_purple .filter-select-style__menu {
    background: rgb(26 35 58 / 90%) !important;
    backdrop-filter: blur(10px);
    border: 1px solid rgb(255 255 255 / 25%) !important;
    box-shadow: inset 0 -1px 12px 3px rgba(255, 255, 255, .05) !important;
}

.gray_scale .filter-select-style__menu, 
.blue_white_gray .filter-select-style__menu, 
.custom-select-style_gray_scale__menu,
.custom-select-style_blue_white_gray__menu,
.filter-select-style_gray_scale__menu,
.filter-select-style_blue_white_gray__menu {
    background: rgb(255 255 255 / 85%) !important;
    backdrop-filter: blur(10px);
}

.black_pink_purple .filter-select-style__option.filter-select-style__option--is-focused:hover,
.claret_blue .filter-select-style__option.filter-select-style__option--is-focused:hover {
    background: var(--rhino) !important;
}

.gray_scale .filter-select-style__option.filter-select-style__option--is-focused:hover,
.custom-select-style_gray_scale__option.custom-select-style_gray_scale__option--is-focused:hover,
.filter-select-style_gray_scale__option.filter-select-style_gray_scale__option--is-focused:hover {
    background: var(--gray-light-border-200) !important;
}


.blue_white_gray .filter-select-style__option.filter-select-style__option--is-focused:hover,
.custom-select-style_blue_white_gray__option.custom-select-style_blue_white_gray__option--is-focused:hover,
.custom-select-style_claret_blue__option.custom-select-style_claret_blue__option--is-focused:hover,
.filter-select-style_blue_white_gray__option.filter-select-style_blue_white_gray__option--is-focused:hover,
.filter-select-style_claret_blue__option.filter-select-style_claret_blue__option--is-focused:hover {
    background: var(--blue-white-gray-light) !important;
    color: var(--white) !important;
}

.custom-select-style_black_pink_purple__option.custom-select-style_black_pink_purple__option--is-focused:hover,
.filter-select-style_black_pink_purple__option.filter-select-style_black_pink_purple__option--is-focused:hover {
    background: var(--blue-white-gray-light) !important;
    color: var(--white) !important;
}

.gray_scale .filter-select-style__option.filter-select-style__option--is-selected.filter-select-style__option--is-focused:hover,
.custom-select-style_gray_scale__option.custom-select-style_gray_scale__option--is-selected.custom-select-style_gray_scale__option--is-focused:hover,
.filter-select-style_gray_scale__option.filter-select-style_gray_scale__option--is-selected.filter-select-style_gray_scale__option--is-focused:hover {
    background: var(--blueCoolColor) !important;
}

.blue_white_gray .filter-select-style__option.filter-select-style__option--is-selected.filter-select-style__option--is-focused:hover,
.custom-select-style_blue_white_gray__option.custom-select-style_blue_white_gray__option--is-selected.custom-select-style_blue_white_gray__option--is-focused:hover,
.custom-select-style_claret_blue__option.custom-select-style_claret_blue__option--is-selected.custom-select-style_claret_blue__option--is-focused:hover,
.filter-select-style_blue_white_gray__option.filter-select-style_blue_white_gray__option--is-selected.filter-select-style_blue_white_gray__option--is-focused:hover,
.filter-select-style_claret_blue__option.filter-select-style_claret_blue__option--is-selected.filter-select-style_claret_blue__option--is-focused:hover {
    background: var(--strongBlue) !important;
}

.custom-select-style_black_pink_purple__option.custom-select-style_black_pink_purple__option--is-selected.custom-select-style_black_pink_purple__option--is-focused:hover,
.filter-select-style_black_pink_purple__option.filter-select-style_black_pink_purple__option--is-selected.filter-select-style_black_pink_purple__option--is-focused:hover {
    background: var(--shadeBlue) !important;
}

.black_pink_purple .filter-select-style__option.filter-select-style__option--is-focused,
.claret_blue .filter-select-style__option.filter-select-style__option--is-focused {
    background: rgb(26 35 58 / 90%) !important;
}

.gray_scale .filter-select-style__option.filter-select-style__option--is-focused,
.blue_white_gray .filter-select-style__option.filter-select-style__option--is-focused,
.custom-select-style_gray_scale__option.custom-select-style_gray_scale__option--is-focused,
.custom-select-style_blue_white_gray__option.custom-select-style_blue_white_gray__option--is-focused,
.custom-select-style_claret_blue__option.custom-select-style_claret_blue__option--is-focused,
.filter-select-style_gray_scale__option.filter-select-style_gray_scale__option--is-focused,
.filter-select-style_blue_white_gray__option.filter-select-style_blue_white_gray__option--is-focused,
.filter-select-style_claret_blue__option.filter-select-style_claret_blue__option--is-focused  {
    background: transparent !important;
}

.custom-select-style_black_pink_purple__option.custom-select-style_black_pink_purple__option--is-focused,
.filter-select-style_black_pink_purple__option.filter-select-style_black_pink_purple__option--is-focused {
    background: transparent !important;
}

.gray_scale .filter-select-style__option.filter-select-style__option--is-selected,
.custom-select-style_gray_scale__option.custom-select-style_gray_scale__option--is-selected,
.filter-select-style_gray_scale__option.filter-select-style_gray_scale__option--is-selected {
    background: var(--gray-light) !important;
}

.blue_white_gray .filter-select-style__option.filter-select-style__option--is-selected,
.custom-select-style_blue_white_gray__option.custom-select-style_blue_white_gray__option--is-selected,
.custom-select-style_claret_blue__option.custom-select-style_claret_blue__option--is-selected,
.filter-select-style_blue_white_gray__option.filter-select-style_blue_white_gray__option--is-selected,
.filter-select-style_claret_blue__option.filter-select-style_claret_blue__option--is-selected {
    background: var(--strongBlue) !important;
}

.custom-select-style_black_pink_purple__option.custom-select-style_black_pink_purple__option--is-selected,
.filter-select-style_black_pink_purple__option.filter-select-style_black_pink_purple__option--is-selected {
    background: var(--shadeBlue) !important;
}

.black_pink_purple .filter-select-style__option.filter-select-style__option--is-selected,
.claret_blue .filter-select-style__option.filter-select-style__option--is-selected {
    background: var(--rhino) !important;
}

.headDrop .custom-select-style__dropdown-indicator,
.headDrop .custom-select-style__single-value {
  color: var(--white) !important;
}

.arrowIcon {
  color: var(--white) !important;
  fill: var(--white) !important;
}

.black_pink_purple .filter-select-style__control,
.claret_blue .filter-select-style__control {
  box-shadow: inset 0 -1px 12px 3px rgb(255 255 255 / 6%) !important;
  border: 1px solid rgb(255 255 255 / 25%) !important;
  background: transparent !important;
}

.blue_white_gray .filter-select-style__control,
.gray_scale .filter-select-style__control {
  border: 1px solid var(--light-blue) !important;
  box-shadow: none !important;
}

.black_pink_purple .filter-select-style__single-value,
.claret_blue .filter-select-style__single-value,
.claret_blue .filter-select-style__indicator.filter-select-style__dropdown-indicator,
.black_pink_purple .filter-select-style__indicator.filter-select-style__dropdown-indicator,
.black_pink_purple .filter-select-style__input,
.claret_blue .filter-select-style__input {
  color: var(--text-light-blue) !important;
}

.black_pink_purple .filter-select-style__option,
.claret_blue .filter-select-style__option {
  color: var(--text-light-blue) !important;
  border-bottom: 1px solid rgb(255 255 255 / 12%) !important;
}

.commonTab .css-tlfecz-indicatorContainer, .commonTab .css-1gtu0rj-indicatorContainer {
  padding: 0px !important;
  margin-right: 4px !important;
}

.filter-select-style__option:last-child,
.filter-select-style__option:last-child,
.custom-select-style_gray_scale__option:last-child,
.custom-select-style_claret_blue__option:last-child,
.custom-select-style_black_pink_purple__option:last-child,
.custom-select-style_blue_white_gray__option:last-child,
.filter-select-style_gray_scale__option:last-child,
.filter-select-style_claret_blue__option:last-child,
.filter-select-style_black_pink_purple__option:last-child,
.filter-select-style_blue_white_gray__option:last-child {
  border: none !important;
}

.black_pink_purple .sidebarList .MuiListItemIcon-root, 
.black_pink_purple .sidebarList .MuiSvgIcon-root, 
.claret_blue .sidebarList .MuiSvgIcon-root, 
.claret_blue .sidebarList .MuiListItemIcon-root, 
.black_pink_purple .sidebarList .MuiListItemText-root, 
.claret_blue .sidebarList .MuiListItemText-root {
    color: var(--lightGrayishBlue) !important;
}

/* DatePicker CSS */
.custom-date-range-picker .ant-picker-panel-container {
  background: rgb(26 35 58 / 90%) !important;
  backdrop-filter: blur(10px);
  border: 1px solid rgb(255 255 255 / 25%) !important;
  box-shadow: inset 0 -1px 12px 3px rgba(255, 255, 255, .05) !important;
}

.custom-date-range-picker .ant-picker-cell {
  color: rgb(255 255 255 / 25%) !important;
}

.custom-date-range-picker .ant-picker-content th {
  color: var(--text-light-blue) !important;
}

.custom-date-range-picker .ant-picker-cell-in-view {
    color: var(--text-light-blue) !important;
}

.custom-date-range-picker .ant-picker-header button {
  color: var(--text-light-blue) !important;
}

.custom-date-range-picker .ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner {
  color: var(--text-light-blue) !important;
}

.custom-date-range-picker .ant-picker-header {
  color: var(--text-light-blue) !important;
}

.custom-date-range-picker .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, .custom-date-range-picker .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .custom-date-range-picker .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner,
.custom-date-range-picker .ant-btn-primary {
    background: rgb(38 74 201 / 60%) !important;
    color: var(--white);
    background-image: url("./app/assets/svg/lines.svg") !important;
    background-size: 150% !important;
    border: 1px solid var(--shadeBlue) !important;
    background-position: center center !important;
}
.custom-date-range-picker .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, .custom-date-range-picker .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .custom-date-range-picker .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  border: 0px !important;
  background: rgb(38 74 201) !important;
}
.custom-date-range-picker .ant-btn-primary[disabled] {
  color: rgb(255 255 255 / 50%) !important;
}
.custom-date-range-picker .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border: 1px solid var(--shadeBlue) !important;
}

.custom-date-range-picker .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: rgb(38 74 201 / 60%) !important;
}

.custom-date-range-picker .ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
  background: var(--rhino) !important;
}

.custom-date-range-picker .ant-picker-header, .custom-date-range-picker .ant-picker-panel {
  border-bottom: 1px solid rgb(255 255 255 / 12%) !important;
}
.custom-date-range-picker .ant-picker-datetime-panel .ant-picker-time-panel, 
.custom-date-range-picker .ant-picker-time-panel-column:not(:first-child) {
  border-left: 1px solid rgb(255 255 255 / 12%) !important;
}

.custom-date-range-picker .ant-picker-range-arrow {
  border-color: rgb(255 255 255 / 25%) rgb(255 255 255 / 25%) transparent transparent !important;
  box-shadow: 2px -2px 6px rgba(0, 0, 0, 0.06);
  border: 6px solid #f0f0f0;
  background: transparent;
  transform: rotate(-45deg) translateY(1px);
  top: 1px;
}

.custom-date-range-picker .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover::before, .custom-date-range-picker .ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-hover::before, .custom-date-range-picker .ant-picker-cell-in-view.ant-picker-cell-range-end.ant-picker-cell-range-hover::before, .custom-date-range-picker .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single).ant-picker-cell-range-hover-start::before, .custom-date-range-picker .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single).ant-picker-cell-range-hover-end::before, .custom-date-range-picker .ant-picker-panel > :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start::before, .custom-date-range-picker .ant-picker-panel > :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end::before {
  background: rgb(38 74 201 / 30%) !important;
}
.custom-date-range-picker .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner::after, .custom-date-range-picker .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner::after {
  background: rgb(38 74 201 / 30%) !important;
}
.custom-date-range-picker .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before, .custom-date-range-picker .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
   background: rgb(38 74 201 / 60%) !important;
}
.custom-date-range-picker .ant-picker-cell-in-view.ant-picker-cell-in-range::before {
  background: rgb(38 74 201 / 60%) !important;
}

.ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
.ant-picker-cell:hover:not(.ant-picker-cell-selected):not(
    .ant-picker-cell-range-start
  ):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(
    .ant-picker-cell-range-hover-end
  )
  .ant-picker-cell-inner {
  background: inherit !important;
}

.ant-picker-range-arrow::before {
  display: none !important;
}

.ant-picker-time-panel-column::-webkit-scrollbar {
  width: 8px;
}

.ant-picker-time-panel-column::-webkit-scrollbar-track {
  background-color: transparent;
}

.ant-picker-time-panel-column::-webkit-scrollbar-thumb {
  background-color: #a7b9bc;
  border-radius: 10px;
  border: 3px solid transparent;
  background-clip: content-box;
}


.gray_scale .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-color: var(--gray-light) !important;
}

.gray_scale .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, .gray_scale .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .gray_scale .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background-color: var(--gray-light);
}

.gray_scale .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background-color: var(--lightGray);
}

.blue_white_gray .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-color: var(--strongBlue) !important;
}

.blue_white_gray .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, .blue_white_gray .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .blue_white_gray .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background-color: var(--strongBlue);
}

.gray_scale .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover::before, .gray_scaler .ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-hover::before, .gray_scale .ant-picker-cell-in-view.ant-picker-cell-range-end.ant-picker-cell-range-hover::before, .gray_scale .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single).ant-picker-cell-range-hover-start::before, .gray_scale .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single).ant-picker-cell-range-hover-end::before, .gray_scale .ant-picker-panel > :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start::before, .gray_scale .ant-picker-panel > :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end::before {
  background: var(--lightGray) !important;
}
.gray_scale .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner::after, .gray_scale .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner::after {
  background: var(--lightGray) !important;
}

.gray_scale .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before, .gray_scale .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
  background-color: var(--greatLightBlueShade) !important;
}

.gray_scale  .ant-picker-cell-in-view.ant-picker-cell-in-range::before {
  background-color: var(--greatLightBlueShade) !important;
}

.gray_scale  .tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:last-child::after, 
.gray_scale  tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:last-child::after, 
.gray_scale  .ant-picker-cell-in-view.ant-picker-cell-end.ant-picker-cell-range-hover-edge-end.ant-picker-cell-range-hover-edge-end-near-range::after, 
.gray_scale  .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-end:not(.ant-picker-cell-range-hover-edge-end-near-range)::after, 
.gray_scale .ant-picker-cell-in-view.ant-picker-cell-range-hover-end::after {
  border-right-color: var(--gray-light) !important;
}

.blue_white_gray  .tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:last-child::after, 
.blue_white_gray  tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:last-child::after, 
.blue_white_gray  .ant-picker-cell-in-view.ant-picker-cell-end.ant-picker-cell-range-hover-edge-end.ant-picker-cell-range-hover-edge-end-near-range::after, 
.blue_white_gray  .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-end:not(.ant-picker-cell-range-hover-edge-end-near-range)::after, 
.blue_white_gray .ant-picker-cell-in-view.ant-picker-cell-range-hover-end::after {
  border-right-color: var(--strongBlue) !important;
}

.gray_scale .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after, 
.gray_scale .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after, 
.gray_scale .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single::after, 
.gray_scale  .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-end-near-hover::after, 
.gray_scale .ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-start-near-hover::after, 
.gray_scale .ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-end-single::after, 
.gray_scale .ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range)::after {
  border-top-color: var(--gray-light) !important;
  border-bottom-color: var(--gray-light) !important;
}

.blue_white_gray .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after, 
.blue_white_gray .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after, 
.blue_white_gray .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single::after, 
.blue_white_gray  .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-end-near-hover::after, 
.blue_white_gray .ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-start-near-hover::after, 
.blue_white_gray .ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-end-single::after, 
.blue_white_gray .ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range)::after {
  border-top-color: var(--strongBlue) !important;
  border-bottom-color: var(--strongBlue) !important;
}

.gray_scale tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:first-child::after, 
.gray_scale tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:first-child::after, 
.gray_scale .ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover-edge-start.ant-picker-cell-range-hover-edge-start-near-range::after, 
.gray_scale .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover-edge-start-near-range)::after, 
.gray_scale .ant-picker-cell-in-view.ant-picker-cell-range-hover-start::after {
  border-left-color: var(--gray-light) !important;
}

.blue_white_gray tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:first-child::after, 
.blue_white_gray tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:first-child::after, 
.blue_white_gray .ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover-edge-start.ant-picker-cell-range-hover-edge-start-near-range::after, 
.blue_white_gray .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover-edge-start-near-range)::after, 
.blue_white_gray .ant-picker-cell-in-view.ant-picker-cell-range-hover-start::after {
  border-left-color: var(--strongBlue) !important;
}
.black_pink_purple .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, 
.black_pink_purple .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, 
.black_pink_purple .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background: var(--shadeBlue) !important;
}
.claret_blue .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, 
.claret_blue .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, 
.claret_blue .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background: var(--strongBlue) !important;
}
.gray_scale .ant-picker-footer .ant-btn-primary {
  background-color: var(--gray-light);
  border-color: var(--gray-light);
  color: #fff;
}

.blue_white_gray .ant-picker-footer .ant-btn-primary {
  background-color: var(--strongBlue);
  border-color: var(--strongBlue);
  color: #fff;
}

.claret_blue .ant-picker-footer .ant-btn-primary {
  background-color: var(--strongBlue);
  border-color: var(--strongBlue);
  color: #fff;
}

.black_pink_purple .ant-picker-footer .ant-btn-primary {
  background-color: var(--shadeBlue);
  border-color: var(--shadeBlue);
  color: #fff;
}

.blue_white_gray .ant-picker-panel-container, .gray_scale .ant-picker-panel-container  {
  background: rgb(255 255 255 / 85%) !important;
  backdrop-filter: blur(15px);
}

.black_pink_purple.btn-secondary:hover,
.black_pink_purple.btn-secondary:focus,
.claret_blue.btn-secondary:hover,
.claret_blue.btn-secondary:focus,
.blue_white_gray.btn-secondary:hover,
.blue_white_gray.btn-secondary:focus {
  box-shadow: var(--pink-claret-btn-shadow) !important;
}

.btn-secondary { 
  font-size: 0.825rem !important;
  color: var(--white);
  padding: 5px 15px !important;
}

.black_pink_purple.btn-secondary {
  background: rgb(38 74 201 / 60%) !important;
  background-size: 140% !important;
  background-position: center center !important;
  background-image: url("./app/assets/svg/lines.svg") !important;
  border: 1px solid var(--shadeBlue) !important;
}

.claret_blue.btn-secondary {
  background: rgb(1 104 181 / 60%) !important;
  border: 1px solid var(--strongBlue) !important;
  background-size: 140% !important;
  background-position: center center !important;
  background-image: url("./app/assets/svg/lines.svg") !important;
}

.gray_scale.btn-secondary {
  background-size: 140% !important;
  background-position: center center !important;
  background-image: url("./app/assets/svg/lines.svg") !important;
}

.gray_scale.btn-secondary:hover,
.gray_scale.btn-secondary:focus {
  box-shadow: 0 0 0 0.2rem rgb(153 153 153 / 30%) !important;
}

.blue_white_gray.btn-secondary {
  background: var(--strongBlue) !important;
  border: 1px solid var(--strongBlue) !important;
  background-size: 140% !important;
  background-position: center center !important;
  background-image: url("./app/assets/svg/lines.svg") !important;
}

.black_pink_purple.outtab.btn-outline-secondary:hover,
.claret_blue.outtab.btn-outline-secondary:hover {
  background: var(--shadeBlue) !important;
  box-shadow: var(--pink-claret-btn-shadow) !important;
}

.blue_white_gray.outtab.btn-outline-secondary:hover {
  background: var(--strongBlue) !important;
  box-shadow: var(--pink-claret-btn-shadow) !important;
}

.black_pink_purple.outtab.btn-secondary.disabled,
.claret_blue.outtab.btn-secondary.disabled {
  background: var(--shadeBlue) !important;
}

.blue_white_gray.outtab.btn-secondary.disabled {
  background: var(--strongBlue);
}


.claret_blue.outtab, .black_pink_purple.outtab {
    color: var(--text-light-blue) !important;
}

.require,
.require.form-control:hover,
.requireSelect .css-1s2u09g-control,
.requireSelect .css-1s2u09g-control,
.require .ant-picker:hover,
.require .ant-picker-focused,
.require.form-control {
  border: 1px solid var(--errorRed) !important;
}

.form-control.is-invalid::placeholder {
  color: var(--errorRed) !important;
  opacity: 1 !important;
}

.require::placeholder,
.requireSelect .css-14el2xx-placeholder,
.requireSelect .css-1wa3eu0-placeholder,
.requireSelect .css-1s2u09g-control:hover .css-tlfecz-indicatorContainer,
.requireSelect .css-tlfecz-indicatorContainer,
.require .anticon,
.require .ant-picker,
.require .ant-picker-input input::placeholder,
.require .ant-select-selection__placeholder {
  color: var(--errorRed) !important;
  opacity: 1;
}

.requireSelect .css-tlfecz-indicatorContainer .css-tj5bde-Svg,
.requireSelect .css-1gtu0rj-indicatorContainer .css-tj5bde-Svg {
    fill: #dc3545 !important;
}

.ToggleBtn #button-2 .knobs:before, .ToggleBtn #button-2 .knobs:after {
  top: 2px !important;
  justify-content: center;
  align-items: center;
  display: flex;
  width: 20px !important;
  height: 20px !important;
  font-size: 8px !important;
  font-weight: 500 !important;
  line-height: 0px !important;
  padding: 0px !important;
}
.ToggleBtn .button {
  width: 52px !important;
  height: 24px !important;
}
.ToggleBtn .toggle-button-cover {
    margin: 20px 0px 0px 0px !important;
}
.ToggleBtn #button-2 .checkbox:checked + .knobs:after {
    right: 2px !important;
}

.black_pink_purple  input.form-control.dark-theme-form-control, 
.claret_blue  input.form-control.dark-theme-form-control {
    color: var(--text-light-blue) !important;
    box-shadow: inset 0 -1px 12px 3px rgb(255 255 255 / 6%) !important;
    border: 1px solid rgb(255 255 255 / 25%) !important;
    background: transparent;
}

.black_pink_purple .form-control.dark-theme-form-control:disabled, .black_pink_purple .form-control.dark-theme-form-control[readonly],
.claret_blue .form-control.dark-theme-form-control:disabled, .claret_blue .form-control.dark-theme-form-control[readonly] {
  background: var(--grayishNavy);
  opacity: 0.6;
  cursor: no-drop;
}

.black_pink_purple .form-control.dark-theme-form-control.is-invalid, .black_pink_purple .was-validated .form-control.dark-theme-form-control:invalid,
.claret_blue .form-control.dark-theme-form-control.is-invalid, .claret_blue .was-validated .form-control.dark-theme-form-control:invalid {
  border: 1px solid #dc3545 !important;
  padding-right: calc(1.5em + .75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 12 12%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23dc3545%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23dc3545%27 stroke=%27none%27/%3e%3c/svg%3e") !important;
  background-repeat: no-repeat !important;
  background-position: right calc(.375em + .1875rem) center !important;
  background-size: calc(.75em + .375rem) calc(.75em + .375rem) !important;
}

.accordianDetails .MuiSlider-thumb.Mui-disabled {
  width: 15px !important;
  height: 15px !important;
  margin-top: -5px !important;
  margin-left: 0px !important;
  background: #efefef !important;
}

.mini-data-card {
  position: relative;
  overflow: hidden;
  width: 33.33% !important;
  margin: 0px 3px;
  border-radius: 5px;
}

.mini-data-card .data-text {
  color: var(--light-primary);
  font-size: 12px;
}

.mini-data-card .heading {
  color: var(--text-light-blue);
  font-size: 13px;
}

.gray_scale .mini-data-card .data-text,
.blue_white_gray .mini-data-card .data-text {
  color: var(--darkCharcoal);
}

.gray_scale .mini-data-card .heading,
.blue_white_gray .mini-data-card .heading {
  color: var(--gray-light);
}

.journey-card .inner-card {
  height: 141px !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}


.inner-card {
  justify-content: center;
  flex-direction: column;
  display: flex;
  padding: 14px 8px;
  position: relative;
  z-index: 1;
  box-shadow: inset 0 -1px 12px 3px rgba(255, 255, 255, .05);
  border: 1px solid rgb(255 255 255 / 12%) !important;
  border-radius: 5px;
  text-align: center;
  background-blend-mode: exclusion;
  background: var(--blueEbonyClay);
  color: var(--white);
  margin: 1px;
}

.gray_scale .inner-card,
.blue_white_gray .inner-card {
  border: 1px solid var(--gray-light-border-200) !important;
  background-color: var(--white) !important;
  box-shadow: 0 3px 3px rgba(56,65,74,.1) !important;
}

.journey-card {
  width: 48% !important;
  margin-bottom: 10px;
}

.journey-card .data-text {
    font-size: 16px !important;
}

.journey-card::before {
  content: "";
  height: 360px;
  display: block;
  width: 70px;
  transform: translate(0);
  position: absolute;
  animation: rotate-border 5s linear forwards infinite;
  z-index: 0;
  top: 50%;
  transform-origin: top center;
}

.journey-card .heading {
    font-size: 18px !important;
}

.black_pink_purple  .journey-card::before { 
  background: var(--black-pink-purple);
}
.claret_blue .journey-card::before { 
  background: var(--claret-blue);
}
.gray_scale .journey-card::before { 
  background: var(--gray-light);
}
.blue_white_gray .journey-card::before { 
  background: var(--strongBlue);
}

.vidchart rect.highcharts-background {
    fill: transparent !important;
}

@keyframes rotate-border {
  0% {
      transform: rotate(0);
  }
  100% {
      transform: rotate(360deg);
  }
}

.auth-screen {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-attachment: fixed !important;
  background: url("./app/assets/png/dark-bg.png");
  background-size: cover !important;
}

.auth-screen:before {
  content: '';
  position: absolute;
  inset: 0;
  z-index: 0;
  background-color: rgb(4 19 36 / 90%);
}

.auth-link-text {
  margin-top: 10px !important;
  color: #CB2026 !important;
  font-weight: 400;
  font-size: 13px;
}

.auth-link-text:hover {
  text-decoration: underline !important;
} 

.auth-link-text .MuiSvgIcon-root {
  fill: currentColor !important;
}

.icon-0 {
  background: linear-gradient(180deg, rgba(54, 60, 60, 1) 0%, rgba(34, 134, 179, 1) 60%);
  box-shadow: 0px 0px 0px 3px rgb(82 135 151 / 30%);
  border: 1px solid #1DA6E6;
  animation: pulse-icon-0 2s infinite;
  animation-duration: 0.9s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-out;
}

@keyframes pulse-icon-0 {
  0% {
    box-shadow: 0px 0px 0px 3px rgb(82 135 151 / 30%);
  }
  70% {
    box-shadow: 0 0 0 10px rgb(249 153 153 / 0%);
  }
  100% {
    box-shadow: 0 0 0 0 rgb(249 153 153 / 0%);
  }
}

.icon-1 {
  background: linear-gradient(180deg, rgba(54,60,60,1) 0%, rgba(98,96,152,1) 60%);
  box-shadow: 0px 0px 0px 3px rgb(105 121 120 / 30%);
  border: 1px solid #7F7AD7;
  animation: pulse-icon-1 2s infinite;
  animation-duration: 0.9s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-out;
}

@keyframes pulse-icon-1 {
  0% {
    box-shadow: 0px 0px 0px 3px rgb(105 121 120 / 30%);
  }
  70% {
    box-shadow: 0 0 0 10px rgb(249 153 153 / 0%);
  }
  100% {
    box-shadow: 0 0 0 0 rgb(249 153 153 / 0%);
  }
}

.icon-2 {
  background: linear-gradient(180deg, rgba(25,24,45,1) 0%, rgba(17,44,97,1) 60%);
  box-shadow: 0px 0px 0px 3px rgb(89 124 146 / 30%);
  border: 1px solid #276CC8;
  animation: pulse-icon-2 2s infinite;
  animation-duration: 0.9s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-out;
}

@keyframes pulse-icon-2 {
  0% {
    box-shadow: 0px 0px 0px 3px rgb(89 124 146 / 30%);
  }
  70% {
    box-shadow: 0 0 0 10px rgb(249 153 153 / 0%);
  }
  100% {
    box-shadow: 0 0 0 0 rgb(249 153 153 / 0%);
  }
}

.icon-3 {
  background: linear-gradient(180deg, rgba(54,60,60,1) 0%, rgba(79,91,121,1) 60%);
  box-shadow: 0px 0px 0px 3px rgb(103 124 124 / 30%);
  border: 1px solid #6A85D5;
  animation: pulse-icon-3 2s infinite;
  animation-duration: 0.9s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-out;
}

@keyframes pulse-icon-3 {
  0% {
    box-shadow: 0px 0px 0px 3px rgb(103 124 124 / 30%);
  }
  70% {
    box-shadow: 0 0 0 10px rgb(249 153 153 / 0%);
  }
  100% {
    box-shadow: 0 0 0 0 rgb(249 153 153 / 0%);
  }
}

/* Trip Details Box CSS */

.trip-card-box {
  border-radius: 5px;
  overflow: hidden;
  height: 130px;
}

.trip-card-box .MuiSvgIcon-root {
  font-size: 26px;
  color: #fff;
  background-color: #ffffff4d;
  border-radius: 50%;
  padding: 4px;
  margin-right: 10px;
}

.trip-card-box .card-label {
  font-size: 16px;
  color: #fff;
  margin-bottom: 10px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
}

.trip-card-box .card-num {
  font-size: 24px;
  color: #fff;
}

.bg-card-0 {
  background-image: linear-gradient(to left, #0db2de 0, #005bea 100%) !important;
}

.bg-card-1 {
  background-image: linear-gradient(to left,#f93a5a,#f7778c)!important;
}

.bg-card-2 {
  background-image: linear-gradient(to left,#48d6a8 0,#029666 100%)!important;
}

.bg-card-3 {
  background-image: linear-gradient(to left,#efa65f,#f76a2d)!important;
}

.path-0 {
  height: auto !important;
  animation:pathAnim-0 4s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

@keyframes pathAnim-0{
  0%{
    d: path("M 0,400 L 0,150 C 29.80169926330624,135.2813326732782 59.60339852661248,120.56266534655641 91,119 C 122.39660147338752,117.43733465344359 155.3881051568563,129.03067128705257 181,149 C 206.6118948431437,168.96932871294743 224.84418084596223,197.31464950523335 248,208 C 271.1558191540378,218.68535049476665 299.2351714592948,211.71073069201407 333,209 C 366.7648285407052,206.28926930798593 406.21513331685827,207.84242772671047 435,188 C 463.78486668314173,168.15757227328953 481.90429527327217,126.919558401144 503,130 C 524.0957047267278,133.080441598856 548.1676855900531,180.47933866871347 577,205 C 605.8323144099469,229.52066133128653 639.4249623665154,231.16308692400204 674,213 C 708.5750376334846,194.83691307599796 744.1324649438851,156.8683136352783 770,146 C 795.8675350561149,135.1316863647217 812.0451778579439,151.36365853488476 839,169 C 865.9548221420561,186.63634146511524 903.6868236243392,205.6770522251826 935,184 C 966.3131763756608,162.3229477748174 991.2075276446997,99.92813256438482 1021,83 C 1050.7924723553003,66.07186743561518 1085.4830657968623,94.61041751727811 1113,122 C 1140.5169342031377,149.3895824827219 1160.860209167851,175.63019736650276 1182,177 C 1203.139790832149,178.36980263349724 1225.0760975317323,154.8687930167108 1258,151 C 1290.9239024682677,147.1312069832892 1334.8354007052192,162.89463056665403 1367,166 C 1399.1645992947808,169.10536943334597 1419.5822996473903,159.55268471667299 1440,150 L 1440,400 L 0,400 Z");
  }
  25%{
    d: path("M 0,400 L 0,150 C 29.357350385001105,152.0354447674112 58.71470077000221,154.07088953482238 87,161 C 115.28529922999779,167.92911046517762 142.49854730499223,179.75188662812164 174,182 C 205.50145269500777,184.24811337187836 241.29111001002894,176.92156395269103 268,148 C 294.70888998997106,119.07843604730897 312.3370126548922,68.56185756111421 340,75 C 367.6629873451078,81.43814243888579 405.3608393704024,144.83100580285208 437,180 C 468.6391606295976,215.16899419714792 494.2196298634982,222.1141192274774 514,203 C 533.7803701365018,183.8858807725226 547.7606411756052,138.7125172872383 578,131 C 608.2393588243948,123.28748271276167 654.7378054340809,153.03581162356926 687,158 C 719.2621945659191,162.96418837643074 737.2881370880713,143.14423621848456 766,148 C 794.7118629119287,152.85576378151544 834.1096462136336,182.38724350249245 861,193 C 887.8903537863664,203.61275649750755 902.273278057394,195.30678977154557 925,190 C 947.726721942606,184.69321022845443 978.7972415567899,182.38559741132534 1008,185 C 1037.20275844321,187.61440258867466 1064.5377557154459,195.15082058315315 1092,194 C 1119.4622442845541,192.84917941684685 1147.0517355814268,183.01112025606204 1178,163 C 1208.9482644185732,142.98887974393796 1243.2553019588465,112.80469839259868 1274,107 C 1304.7446980411535,101.19530160740132 1331.9270565831866,119.77008617354323 1359,131 C 1386.0729434168134,142.22991382645677 1413.0364717084067,146.11495691322838 1440,150 L 1440,400 L 0,400 Z");
  }
  50%{
    d: path("M 0,400 L 0,150 C 32.575647571210155,166.49227184595554 65.15129514242031,182.9845436919111 92,203 C 118.84870485757969,223.0154563080889 139.9704670015289,246.55409707831114 169,222 C 198.0295329984711,197.44590292168886 234.96683685146394,124.79906799484426 264,98 C 293.03316314853606,71.20093200515574 314.16218559261523,90.24963094231177 340,119 C 365.83781440738477,147.75036905768823 396.38442077807514,186.2024082359087 427,186 C 457.61557922192486,185.7975917640913 488.3001312950844,146.94073611405335 513,130 C 537.6998687049156,113.05926388594665 556.4150540415872,118.03464730787786 581,122 C 605.5849459584128,125.96535269212214 636.0396525385673,128.92067465443523 668,120 C 699.9603474614327,111.07932534556475 733.4263358041435,90.28265407438116 760,92 C 786.5736641958565,93.71734592561884 806.2550042448588,117.94870904804009 831,130 C 855.7449957551412,142.0512909519599 885.5536472164212,141.9225097334585 918,138 C 950.4463527835788,134.0774902665415 985.5304068894563,126.36125201812587 1012,149 C 1038.4695931105437,171.63874798187413 1056.3247252257536,224.6324821940381 1087,206 C 1117.6752747742464,187.3675178059619 1161.17069220753,97.10881920572176 1194,95 C 1226.82930779247,92.89118079427824 1248.9925059441266,178.9322409830749 1270,185 C 1291.0074940558734,191.0677590169251 1310.8592840159638,117.16221686197859 1339,98 C 1367.1407159840362,78.83778313802141 1403.5703579920182,114.4188915690107 1440,150 L 1440,400 L 0,400 Z");
  }
  75%{
    d: path("M 0,400 L 0,150 C 35.55350052769779,117.2627523537418 71.10700105539559,84.5255047074836 100,82 C 128.89299894460441,79.4744952925164 151.12549630611545,107.16073352380738 178,114 C 204.87450369388455,120.83926647619262 236.3910137201425,106.83156119728686 264,110 C 291.6089862798575,113.16843880271314 315.3104488133147,133.51302168704512 344,138 C 372.6895511866853,142.48697831295488 406.3671910265987,131.11635205453268 437,131 C 467.6328089734013,130.88364794546732 495.22078708029085,142.0215700948242 522,164 C 548.7792129197092,185.9784299051758 574.7496606522375,218.79736756617046 602,204 C 629.2503393477625,189.20263243382954 657.7805703107593,126.78895964049386 685,121 C 712.2194296892407,115.21104035950614 738.1280581047254,166.0467938718541 762,184 C 785.8719418952746,201.9532061281459 807.7071972703394,187.0238648720897 836,167 C 864.2928027296606,146.9761351279103 899.0431528139175,121.85774663978714 930,124 C 960.9568471860825,126.14225336021286 988.1201914739911,155.5451485687617 1020,188 C 1051.879808526009,220.4548514312383 1088.4760812901184,255.96165908516605 1114,223 C 1139.5239187098816,190.03834091483395 1153.9754833655352,88.60821509057405 1181,78 C 1208.0245166344648,67.39178490942595 1247.6219852477404,147.60548055253776 1279,173 C 1310.3780147522596,198.39451944746224 1333.5365756435026,168.96986269927493 1359,156 C 1384.4634243564974,143.03013730072507 1412.2317121782487,146.51506865036254 1440,150 L 1440,400 L 0,400 Z");
  }
  100%{
    d: path("M 0,400 L 0,150 C 29.80169926330624,135.2813326732782 59.60339852661248,120.56266534655641 91,119 C 122.39660147338752,117.43733465344359 155.3881051568563,129.03067128705257 181,149 C 206.6118948431437,168.96932871294743 224.84418084596223,197.31464950523335 248,208 C 271.1558191540378,218.68535049476665 299.2351714592948,211.71073069201407 333,209 C 366.7648285407052,206.28926930798593 406.21513331685827,207.84242772671047 435,188 C 463.78486668314173,168.15757227328953 481.90429527327217,126.919558401144 503,130 C 524.0957047267278,133.080441598856 548.1676855900531,180.47933866871347 577,205 C 605.8323144099469,229.52066133128653 639.4249623665154,231.16308692400204 674,213 C 708.5750376334846,194.83691307599796 744.1324649438851,156.8683136352783 770,146 C 795.8675350561149,135.1316863647217 812.0451778579439,151.36365853488476 839,169 C 865.9548221420561,186.63634146511524 903.6868236243392,205.6770522251826 935,184 C 966.3131763756608,162.3229477748174 991.2075276446997,99.92813256438482 1021,83 C 1050.7924723553003,66.07186743561518 1085.4830657968623,94.61041751727811 1113,122 C 1140.5169342031377,149.3895824827219 1160.860209167851,175.63019736650276 1182,177 C 1203.139790832149,178.36980263349724 1225.0760975317323,154.8687930167108 1258,151 C 1290.9239024682677,147.1312069832892 1334.8354007052192,162.89463056665403 1367,166 C 1399.1645992947808,169.10536943334597 1419.5822996473903,159.55268471667299 1440,150 L 1440,400 L 0,400 Z");
  }
}

.leaflet-touch .leaflet-control-layers, .leaflet-touch .leaflet-bar {
  border: 1px solid rgba(0, 0, 0, 0.2) !important;
  overflow: hidden;
  font-family: "Poppins", sans-serif !important;
  background-color: #ffffff59 !important;
  backdrop-filter: blur(10px) !important;
}
.leaflet-bar a, .leaflet-bar a:hover {
  overflow: hidden;
  font-family: "Poppins", sans-serif !important;
  background-color: #ffffff59 !important;
  backdrop-filter: blur(10px) !important;
}
/* End CSS */

/* z-index css */
.z-10 {
  z-index: 10;
}
.z-9999 {
  z-index: 9999;
}

.filter-icons svg {
  width: 16px !important;
  height: 16px!important;
  fill: var(--white) !important;
  margin-right: 4px;
}
.filter-icons {
  transition: all ease 50ms !important;
  position: relative;
  padding: 10px;
  border-radius: 5px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 13px;
}

.filter-icons:hover {
  background: rgb(var(--light-rgb));
}
.header-icons-btn.filter-btn {
  width: 100% !important;
  overflow: hidden !important;
}

.blue_white_gray .filter-icons:hover, 
.gray_scale .filter-icons:hover {
    background: #f3f6f8 !important;
}
.blue_white_gray .filter-icons, 
.gray_scale .filter-icons {
  color: #808793 !important;
}

.blue_white_gray .header-icons-btn.filter-btn,
.gray_scale .header-icons-btn.filter-btn { 
  border: 1px solid var(--light-blue);
}

.blue_white_gray .filter-icons svg, .gray_scale .filter-icons svg {
  fill: #808793 !important;
}
.blue_white_gray .filter-icons .spinner-border-sm.text-light, .gray_scale .filter-icons .spinner-border-sm.text-light {
  color: #808793 !important;
}